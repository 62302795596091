// Class: openfl.display._internal.DOMDisplayObjectContainer

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
function openfl__$Vector_VectorIterator() {return require("./../../../openfl/_Vector/VectorIterator");}
function openfl_display__$internal_DOMDisplayObject() {return require("./../../../openfl/display/_internal/DOMDisplayObject");}

// Constructor

var DOMDisplayObjectContainer = function(){}

// Meta

DOMDisplayObjectContainer.__name__ = "openfl.display._internal.DOMDisplayObjectContainer";
DOMDisplayObjectContainer.__isInterface__ = false;
DOMDisplayObjectContainer.prototype = {
	
};
DOMDisplayObjectContainer.prototype.__class__ = DOMDisplayObjectContainer.prototype.constructor = $hxClasses["openfl.display._internal.DOMDisplayObjectContainer"] = DOMDisplayObjectContainer;

// Init



// Statics

DOMDisplayObjectContainer.renderDrawable = function(displayObjectContainer,renderer) {
	var orphan = new (openfl__$Vector_VectorIterator().default)(displayObjectContainer.__removedChildren);
	while(orphan.hasNext()) {
		var orphan1 = orphan.next();
		if(orphan1.stage == null) {
			renderer.__renderDrawable(orphan1);
		}
	}
	displayObjectContainer.__cleanupRemovedChildren();
	(openfl_display__$internal_DOMDisplayObject().default).renderDrawable(displayObjectContainer,renderer);
	if(displayObjectContainer.__cacheBitmap != null && !displayObjectContainer.__isCacheBitmapRender) {
		return;
	}
	renderer.__pushMaskObject(displayObjectContainer);
	if(renderer.__stage != null) {
		var _g = 0;
		var _g1 = displayObjectContainer.__children;
		while(_g < _g1.length) {
			var child = _g1[_g];
			++_g;
			renderer.__renderDrawable(child);
			child.__renderDirty = false;
		}
		displayObjectContainer.__renderDirty = false;
	} else {
		var _g2 = 0;
		var _g11 = displayObjectContainer.__children;
		while(_g2 < _g11.length) {
			var child1 = _g11[_g2];
			++_g2;
			renderer.__renderDrawable(child1);
		}
	}
	renderer.__popMaskObject(displayObjectContainer);
}
DOMDisplayObjectContainer.renderDrawableClear = function(displayObjectContainer,renderer) {
	var orphan = new (openfl__$Vector_VectorIterator().default)(displayObjectContainer.__removedChildren);
	while(orphan.hasNext()) {
		var orphan1 = orphan.next();
		if(orphan1.stage == null) {
			renderer.__renderDrawableClear(orphan1);
		}
	}
	displayObjectContainer.__cleanupRemovedChildren();
	var _g = 0;
	var _g1 = displayObjectContainer.__children;
	while(_g < _g1.length) {
		var child = _g1[_g];
		++_g;
		renderer.__renderDrawableClear(child);
	}
}


// Export

exports.default = DOMDisplayObjectContainer;