// Class: openfl.filters._GlowFilter.CombineShader

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
var $extend = require("./../../../extend_stub").default;
function openfl_filters_BitmapFilterShader() {return require("./../../../openfl/filters/BitmapFilterShader");}

// Constructor

var CombineShader = function() {
	if(this.__glFragmentSource == null) {
		this.__glFragmentSource = "\r\n\t\tuniform sampler2D openfl_Texture;\r\n\t\tuniform sampler2D sourceBitmap;\r\n\t\tvarying vec4 textureCoords;\r\n\r\n\t\tvoid main(void) {\r\n\t\t\tvec4 src = texture2D(sourceBitmap, textureCoords.xy);\r\n\t\t\tvec4 glow = texture2D(openfl_Texture, textureCoords.zw);\r\n\r\n\t\t\tgl_FragColor = src + glow * (1.0 - src.a);\r\n\t\t}\r\n\t";
	}
	if(this.__glVertexSource == null) {
		this.__glVertexSource = "attribute vec4 openfl_Position;\r\n\t\tattribute vec2 openfl_TextureCoord;\r\n\t\tuniform mat4 openfl_Matrix;\r\n\t\tuniform vec2 openfl_TextureSize;\r\n\t\tuniform vec2 offset;\r\n\t\tvarying vec4 textureCoords;\r\n\r\n\t\tvoid main(void) {\r\n\t\t\tgl_Position = openfl_Matrix * openfl_Position;\r\n\t\t\ttextureCoords = vec4(openfl_TextureCoord, openfl_TextureCoord - offset / openfl_TextureSize);\r\n\t\t}\r\n\t";
	}
	(openfl_filters_BitmapFilterShader().default).call(this);
	this.offset.value = [0,0];
	this.__isGenerated = true;
	this.__initGL();
}

// Meta

CombineShader.__name__ = "openfl.filters._GlowFilter.CombineShader";
CombineShader.__isInterface__ = false;
CombineShader.__super__ = (openfl_filters_BitmapFilterShader().default);
CombineShader.prototype = $extend((openfl_filters_BitmapFilterShader().default).prototype, {
	
});
CombineShader.prototype.__class__ = CombineShader.prototype.constructor = $hxClasses["openfl.filters._GlowFilter.CombineShader"] = CombineShader;

// Init



// Statics




// Export

exports.default = CombineShader;