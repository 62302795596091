(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("howler"), require("pako"));
	else if(typeof define === 'function' && define.amd)
		define(["howler", "pako"], factory);
	else if(typeof exports === 'object')
		exports["openfl"] = factory(require("howler"), require("pako"));
	else
		root["openfl"] = factory(root["window"], root["pako"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__79580__, __WEBPACK_EXTERNAL_MODULE__21365__) {
return 