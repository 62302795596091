Object.defineProperty (module.exports, "__esModule", { value: true });
module.exports.StageAlign = module.exports.default = {
	BOTTOM: "bottom",
	BOTTOM_LEFT: "bottomLeft",
	BOTTOM_RIGHT: "bottomRight",
	LEFT: "left",
	RIGHT: "right",
	TOP: "top",
	TOP_LEFT: "topLeft",
	TOP_RIGHT: "topRight",
};