// Class: swf.exporters.swflite.timeline.SymbolTimeline

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../../hxClasses_stub").default;
var $hxEnums = require("./../../../../hxEnums_stub").default;
var $import = require("./../../../../import_stub").default;
var $bind = require("./../../../../bind_stub").default;
var $extend = require("./../../../../extend_stub").default;
function openfl_display_Timeline() {return require("./../../../../openfl/display/Timeline");}
function haxe_ds_IntMap() {return require("./../../../../haxe/ds/IntMap");}
function swf_exporters_swflite_timeline_FrameObjectType() {return require("./../../../../swf/exporters/swflite/timeline/FrameObjectType");}
function swf_exporters_swflite_timeline__$SymbolTimeline_FrameSymbolInstance() {return require("./../../../../swf/exporters/swflite/timeline/_SymbolTimeline/FrameSymbolInstance");}
function openfl_display_MovieClip() {return require("./../../../../openfl/display/MovieClip");}
function openfl_geom_ColorTransform() {return require("./../../../../openfl/geom/ColorTransform");}
function openfl_filters_BlurFilter() {return require("./../../../../openfl/filters/BlurFilter");}
function openfl_filters_ColorMatrixFilter() {return require("./../../../../openfl/filters/ColorMatrixFilter");}
function openfl_filters_DropShadowFilter() {return require("./../../../../openfl/filters/DropShadowFilter");}
function openfl_filters_GlowFilter() {return require("./../../../../openfl/filters/GlowFilter");}
function Reflect() {return require("./../../../../Reflect");}
function openfl_display_FrameLabel() {return require("./../../../../openfl/display/FrameLabel");}
function openfl_display_FrameScript() {return require("./../../../../openfl/display/FrameScript");}
function haxe_CallStack() {return require("./../../../../haxe/CallStack");}
function js__$Boot_HaxeError() {return require("./../../../../js/_Boot/HaxeError");}
function Std() {return require("./../../../../Std");}
function lime_utils_Log() {return require("./../../../../lime/utils/Log");}
function openfl_display_Scene() {return require("./../../../../openfl/display/Scene");}

// Constructor

var SymbolTimeline = function(swf1,symbol) {
	(openfl_display_Timeline().default).call(this);
	this.__swf = swf1;
	this.__symbol = symbol;
	this.__previousFrame = -1;
	var labels = [];
	this.scripts = [];
	var frame;
	var frameData;
	var _g = 0;
	var _g1 = this.__symbol.frames.length;
	while(_g < _g1) {
		var i = _g++;
		frame = i + 1;
		frameData = this.__symbol.frames[i];
		if(frameData.label != null) {
			labels.push(new (openfl_display_FrameLabel().default)(frameData.label,i + 1));
		}
		if(frameData.script != null) {
			this.scripts.push(new (openfl_display_FrameScript().default)((function() {
				return function(scope) {
					frameData.script();
				};
			})(),frame));
		} else if(frameData.scriptSource != null) {
			try {
				var script = [eval(("(function(){" + frameData.scriptSource + "})"))];
				var wrapper = (function(script1) {
					return function(scope1) {
						try {
							script1[0].call(scope1);
						} catch( e ) {
							(haxe_CallStack().default).lastException = e;
							var e1 = ((e) instanceof (js__$Boot_HaxeError().default)) ? e.val : e;
							var wrapper1 = "Error evaluating frame script\n " + (Std().default).string(e1) + "\n";
							var _this = (haxe_CallStack().default).exceptionStack();
							var result = new Array(_this.length);
							var _g2 = 0;
							var _g11 = _this.length;
							while(_g2 < _g11) {
								var i1 = _g2++;
								result[i1] = _this[i1][2];
							}
							(lime_utils_Log().default).info(wrapper1 + result.join("\n") + "\n" + (Std().default).string(e1.stack) + "\n" + script1[0].toString(),{ fileName : "../node_modules/swf/src/swf/exporters/swflite/timeline/SymbolTimeline.hx", lineNumber : 134, className : "swf.exporters.swflite.timeline.SymbolTimeline", methodName : "new"});
						}
					};
				})(script);
				this.scripts.push(new (openfl_display_FrameScript().default)(wrapper,frame));
			} catch( e2 ) {
				(haxe_CallStack().default).lastException = e2;
				var e3 = ((e2) instanceof (js__$Boot_HaxeError().default)) ? e2.val : e2;
				if(this.__symbol.className != null) {
					(lime_utils_Log().default).warn("Unable to evaluate frame script source for symbol \"" + this.__symbol.className + "\" frame " + frame + "\n" + frameData.scriptSource,{ fileName : "../node_modules/swf/src/swf/exporters/swflite/timeline/SymbolTimeline.hx", lineNumber : 155, className : "swf.exporters.swflite.timeline.SymbolTimeline", methodName : "new"});
				} else {
					(lime_utils_Log().default).warn("Unable to evaluate frame script source:\n" + frameData.scriptSource,{ fileName : "../node_modules/swf/src/swf/exporters/swflite/timeline/SymbolTimeline.hx", lineNumber : 160, className : "swf.exporters.swflite.timeline.SymbolTimeline", methodName : "new"});
				}
			}
		}
	}
	if(!SymbolTimeline.__useParentFPS) {
		this.frameRate = swf1.frameRate;
	}
	this.scenes = [new (openfl_display_Scene().default)("",labels,this.__symbol.frames.length)];
}

// Meta

SymbolTimeline.__name__ = "swf.exporters.swflite.timeline.SymbolTimeline";
SymbolTimeline.__isInterface__ = false;
SymbolTimeline.__super__ = (openfl_display_Timeline().default);
SymbolTimeline.prototype = $extend((openfl_display_Timeline().default).prototype, {
	attachMovieClip: function(movieClip) {
		if(this.__activeInstances != null) {
			return;
		}
		this.__movieClip = movieClip;
		this.__activeInstances = [];
		this.__activeInstancesByFrameObjectID = new (haxe_ds_IntMap().default)();
		this.__previousFrame = -1;
		var frame;
		var frameData;
		var instance;
		var duplicate;
		var symbol;
		var displayObject;
		var _g = 0;
		var _g1 = this.scenes[0].numFrames;
		while(_g < _g1) {
			var i = _g++;
			frame = i + 1;
			frameData = this.__symbol.frames[i];
			if(frameData.objects == null) {
				continue;
			}
			var _g2 = 0;
			var _g11 = frameData.objects;
			while(_g2 < _g11.length) {
				var frameObject = _g11[_g2];
				++_g2;
				if(frameObject.type == (swf_exporters_swflite_timeline_FrameObjectType().default).CREATE) {
					if(this.__activeInstancesByFrameObjectID.exists(frameObject.id)) {
						continue;
					} else {
						instance = null;
						duplicate = false;
						var _g3 = 0;
						var _g12 = this.__activeInstances;
						while(_g3 < _g12.length) {
							var activeInstance = _g12[_g3];
							++_g3;
							if(activeInstance.displayObject != null && activeInstance.characterID == frameObject.symbol && activeInstance.depth == frameObject.depth) {
								instance = activeInstance;
								duplicate = true;
								break;
							}
						}
					}
					if(instance == null) {
						symbol = this.__swf.symbols.get(frameObject.symbol);
						if(symbol != null) {
							displayObject = symbol.__createObject(this.__swf);
							if(displayObject != null) {
								instance = new (swf_exporters_swflite_timeline__$SymbolTimeline_FrameSymbolInstance().default)(frame,frameObject.id,frameObject.symbol,frameObject.depth,displayObject,frameObject.clipDepth);
							}
						}
					}
					if(instance != null) {
						this.__activeInstancesByFrameObjectID.set(frameObject.id,instance);
						if(!duplicate) {
							this.__activeInstances.push(instance);
							this.__updateDisplayObject(instance.displayObject,frameObject);
						}
					}
				}
			}
		}
		this.enterFrame(1);
	},
	enterFrame: function(targetFrame) {
		if(this.__symbol != null && targetFrame != this.__previousFrame) {
			this.__updateFrameLabel();
			var currentInstancesByFrameObjectID = new (haxe_ds_IntMap().default)();
			var frame;
			var frameData;
			var instance;
			var _g = 0;
			var _g1 = targetFrame;
			while(_g < _g1) {
				var i = _g++;
				frame = i + 1;
				frameData = this.__symbol.frames[i];
				if(frameData.objects == null) {
					continue;
				}
				var _g2 = 0;
				var _g11 = frameData.objects;
				while(_g2 < _g11.length) {
					var frameObject = _g11[_g2];
					++_g2;
					switch(frameObject.type._hx_index) {
					case 0:
						instance = this.__activeInstancesByFrameObjectID.get(frameObject.id);
						if(instance != null) {
							currentInstancesByFrameObjectID.set(frameObject.id,instance);
							this.__updateDisplayObject(instance.displayObject,frameObject,true);
						}
						break;
					case 1:
						instance = currentInstancesByFrameObjectID.get(frameObject.id);
						if(instance != null && instance.displayObject != null) {
							this.__updateDisplayObject(instance.displayObject,frameObject);
						}
						break;
					case 2:
						currentInstancesByFrameObjectID.remove(frameObject.id);
						break;
					}
				}
			}
			var currentInstances = [];
			var currentMasks = [];
			var instance1 = currentInstancesByFrameObjectID.iterator();
			while(instance1.hasNext()) {
				var instance2 = instance1.next();
				if(currentInstances.indexOf(instance2) == -1) {
					currentInstances.push(instance2);
					if(instance2.clipDepth > 0) {
						currentMasks.push(instance2);
					}
				}
			}
			currentInstances.sort($bind(this,this.__sortDepths));
			var existingChild;
			var targetDepth;
			var targetChild;
			var child;
			var maskApplied;
			var _g21 = 0;
			var _g3 = currentInstances.length;
			while(_g21 < _g3) {
				var i1 = _g21++;
				existingChild = this.__movieClip.getChildAt(i1);
				instance = currentInstances[i1];
				targetDepth = instance.depth;
				targetChild = instance.displayObject;
				if(existingChild != targetChild) {
					child = targetChild;
					this.__movieClip.addChildAt(targetChild,i1);
				} else {
					child = this.__movieClip.getChildAt(i1);
				}
				maskApplied = false;
				var _g22 = 0;
				while(_g22 < currentMasks.length) {
					var mask = currentMasks[_g22];
					++_g22;
					if(targetDepth > mask.depth && targetDepth <= mask.clipDepth) {
						child.set_mask(mask.displayObject);
						maskApplied = true;
						break;
					}
				}
				if(currentMasks.length > 0 && !maskApplied && child.get_mask() != null) {
					child.set_mask(null);
				}
			}
			var child1;
			var i2 = currentInstances.length;
			var length = this.__movieClip.get_numChildren();
			while(i2 < length) {
				child1 = this.__movieClip.getChildAt(i2);
				var _g4 = 0;
				var _g5 = this.__activeInstances;
				while(_g4 < _g5.length) {
					var instance3 = _g5[_g4];
					++_g4;
					if(instance3.displayObject == child1) {
						if(((child1) instanceof (openfl_display_MovieClip().default))) {
							var movie = child1;
							movie.gotoAndPlay(1);
						}
						this.__movieClip.removeChild(child1);
						--i2;
						--length;
					}
				}
				++i2;
			}
			this.__previousFrame = targetFrame;
		}
	},
	__sortDepths: function(a,b) {
		return a.depth - b.depth;
	},
	__updateDisplayObject: function(displayObject,frameObject,reset) {
		if(reset == null) {
			reset = false;
		}
		if(displayObject == null) {
			return;
		}
		if(frameObject.name != null) {
			displayObject.set_name(frameObject.name);
		}
		if(frameObject.matrix != null) {
			displayObject.get_transform().set_matrix(frameObject.matrix);
		}
		if(frameObject.colorTransform != null) {
			displayObject.get_transform().set_colorTransform(frameObject.colorTransform);
		} else if(reset && !displayObject.get_transform().get_colorTransform().__isDefault(true)) {
			displayObject.get_transform().set_colorTransform(new (openfl_geom_ColorTransform().default)());
		}
		displayObject.set_transform(displayObject.get_transform());
		if(frameObject.filters != null) {
			var filters = [];
			var _g = 0;
			var _g1 = frameObject.filters;
			while(_g < _g1.length) {
				var filter = _g1[_g];
				++_g;
				switch(filter._hx_index) {
				case 0:
					var quality = filter.quality;
					var blurY = filter.blurY;
					var blurX = filter.blurX;
					filters.push(new (openfl_filters_BlurFilter().default)(blurX,blurY,quality));
					break;
				case 1:
					var matrix = filter.matrix;
					filters.push(new (openfl_filters_ColorMatrixFilter().default)(matrix));
					break;
				case 2:
					var hideObject = filter.hideObject;
					var knockout = filter.knockout;
					var inner = filter.inner;
					var quality1 = filter.quality;
					var strength = filter.strength;
					var blurY1 = filter.blurY;
					var blurX1 = filter.blurX;
					var alpha = filter.alpha;
					var color = filter.color;
					var angle = filter.angle;
					var distance = filter.distance;
					filters.push(new (openfl_filters_DropShadowFilter().default)(distance,angle,color,alpha,blurX1,blurY1,strength,quality1,inner,knockout,hideObject));
					break;
				case 3:
					var knockout1 = filter.knockout;
					var inner1 = filter.inner;
					var quality2 = filter.quality;
					var strength1 = filter.strength;
					var blurY2 = filter.blurY;
					var blurX2 = filter.blurX;
					var alpha1 = filter.alpha;
					var color1 = filter.color;
					filters.push(new (openfl_filters_GlowFilter().default)(color1,alpha1,blurX2,blurY2,strength1,quality2,inner1,knockout1));
					break;
				}
			}
			displayObject.set_filters(filters);
		} else {
			displayObject.set_filters(null);
		}
		if(frameObject.visible != null) {
			displayObject.set_visible(frameObject.visible);
		}
		if(frameObject.blendMode != null) {
			displayObject.set_blendMode(frameObject.blendMode);
		}
		if(frameObject.cacheAsBitmap != null) {
			displayObject.set_cacheAsBitmap(frameObject.cacheAsBitmap);
		}
		(Reflect().default).setField(this.__movieClip,displayObject.get_name(),displayObject);
	},
	__updateInstanceFields: function() {
		if(this.__instanceFields == null) {
			return;
		}
		var _g = 0;
		var _g1 = this.__instanceFields;
		while(_g < _g1.length) {
			var field = _g1[_g];
			++_g;
			var length = this.__movieClip.get_numChildren();
			var _g2 = 0;
			var _g11 = length;
			while(_g2 < _g11) {
				var i = _g2++;
				var child = this.__movieClip.getChildAt(i);
				if(child.get_name() == field) {
					(Reflect().default).setField(this.__movieClip,field,child);
					break;
				}
			}
		}
	}
});
SymbolTimeline.prototype.__class__ = SymbolTimeline.prototype.constructor = $hxClasses["swf.exporters.swflite.timeline.SymbolTimeline"] = SymbolTimeline;

// Init

{
	SymbolTimeline.__useParentFPS = true;
	/// #if (typeof ENV === 'undefined' || (!ENV['swflite-parent-fps'] && !ENV['swf-parent-fps'])) && (typeof swf_parent_fps === 'undefined' || !swf_parent_fps) && (typeof swflite_parent_fps === 'undefined' || !swflite-parent-fps) && (typeof defines === 'undefined' || (!defines['swf-parent-fps'] && !defines['swflite-parent-fps']))
	SymbolTimeline.__useParentFPS = false;
	/// #endif 
};

// Statics




// Export

exports.default = SymbolTimeline;