// Class: openfl.display._internal.Context3DMaskShader

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
var $extend = require("./../../../extend_stub").default;
function openfl_display_Shader() {return require("./../../../openfl/display/Shader");}
function openfl_display_BitmapData() {return require("./../../../openfl/display/BitmapData");}

// Constructor

var Context3DMaskShader = function() {
	if(this.__glFragmentSource == null) {
		this.__glFragmentSource = "varying vec2 openfl_TextureCoordv;\r\n\r\n\t\tuniform sampler2D openfl_Texture;\r\n\r\n\t\tvoid main(void) {\r\n\r\n\t\t\tvec4 color = texture2D (openfl_Texture, openfl_TextureCoordv);\r\n\r\n\t\t\tif (color.a == 0.0) {\r\n\r\n\t\t\t\tdiscard;\r\n\r\n\t\t\t} else {\r\n\r\n\t\t\t\tgl_FragColor = color;\r\n\r\n\t\t\t}\r\n\r\n\t\t}";
	}
	if(this.__glVertexSource == null) {
		this.__glVertexSource = "attribute vec4 openfl_Position;\r\n\t\tattribute vec2 openfl_TextureCoord;\r\n\t\tvarying vec2 openfl_TextureCoordv;\r\n\r\n\t\tuniform mat4 openfl_Matrix;\r\n\r\n\t\tvoid main(void) {\r\n\r\n\t\t\topenfl_TextureCoordv = openfl_TextureCoord;\r\n\r\n\t\t\tgl_Position = openfl_Matrix * openfl_Position;\r\n\r\n\t\t}";
	}
	(openfl_display_Shader().default).call(this);
	this.__isGenerated = true;
	this.__initGL();
}

// Meta

Context3DMaskShader.__name__ = "openfl.display._internal.Context3DMaskShader";
Context3DMaskShader.__isInterface__ = false;
Context3DMaskShader.__super__ = (openfl_display_Shader().default);
Context3DMaskShader.prototype = $extend((openfl_display_Shader().default).prototype, {
	
});
Context3DMaskShader.prototype.__class__ = Context3DMaskShader.prototype.constructor = $hxClasses["openfl.display._internal.Context3DMaskShader"] = Context3DMaskShader;

// Init



// Statics


Context3DMaskShader.opaqueBitmapData = new (openfl_display_BitmapData().default)(1,1,false,0)

// Export

exports.default = Context3DMaskShader;