// Class: openfl.display._internal.Context3DTextField

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
function openfl_display__$internal_CanvasTextField() {return require("./../../../openfl/display/_internal/CanvasTextField");}
function openfl_display__$internal_Context3DBitmap() {return require("./../../../openfl/display/_internal/Context3DBitmap");}
function openfl_display__$internal_Context3DDisplayObject() {return require("./../../../openfl/display/_internal/Context3DDisplayObject");}

// Constructor

var Context3DTextField = function(){}

// Meta

Context3DTextField.__name__ = "openfl.display._internal.Context3DTextField";
Context3DTextField.__isInterface__ = false;
Context3DTextField.prototype = {
	
};
Context3DTextField.prototype.__class__ = Context3DTextField.prototype.constructor = $hxClasses["openfl.display._internal.Context3DTextField"] = Context3DTextField;

// Init



// Statics

Context3DTextField.render = function(textField,renderer) {
	(openfl_display__$internal_CanvasTextField().default).render(textField,renderer.__softwareRenderer,textField.__worldTransform);
	textField.__graphics.__hardwareDirty = false;
}
Context3DTextField.renderDrawable = function(textField,renderer) {
	renderer.__updateCacheBitmap(textField,false);
	if(textField.__cacheBitmap != null && !textField.__isCacheBitmapRender) {
		(openfl_display__$internal_Context3DBitmap().default).render(textField.__cacheBitmap,renderer);
	} else {
		Context3DTextField.render(textField,renderer);
		(openfl_display__$internal_Context3DDisplayObject().default).render(textField,renderer);
	}
	renderer.__renderEvent(textField);
}
Context3DTextField.renderDrawableMask = function(textField,renderer) {
	Context3DTextField.renderMask(textField,renderer);
	(openfl_display__$internal_Context3DDisplayObject().default).renderDrawableMask(textField,renderer);
}
Context3DTextField.renderMask = function(textField,renderer) {
	(openfl_display__$internal_CanvasTextField().default).render(textField,renderer.__softwareRenderer,textField.__worldTransform);
	textField.__graphics.__hardwareDirty = false;
}
Context3DTextField.__meta__ = { obj : { SuppressWarnings : ["checkstyle:FieldDocComment"]}}

// Export

exports.default = Context3DTextField;