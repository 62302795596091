// Class: openfl.display._internal.CanvasBitmapData

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
function lime_graphics_ImageType() {return require("./../../../lime/graphics/ImageType");}
function lime__$internal_graphics_ImageCanvasUtil() {return require("./../../../lime/_internal/graphics/ImageCanvasUtil");}

// Constructor

var CanvasBitmapData = function(){}

// Meta

CanvasBitmapData.__name__ = "openfl.display._internal.CanvasBitmapData";
CanvasBitmapData.__isInterface__ = false;
CanvasBitmapData.prototype = {
	
};
CanvasBitmapData.prototype.__class__ = CanvasBitmapData.prototype.constructor = $hxClasses["openfl.display._internal.CanvasBitmapData"] = CanvasBitmapData;

// Init



// Statics

CanvasBitmapData.renderDrawable = function(bitmapData,renderer) {
	if(!bitmapData.readable) {
		return;
	}
	var image = bitmapData.image;
	if(image.type == (lime_graphics_ImageType().default).DATA) {
		(lime__$internal_graphics_ImageCanvasUtil().default).convertToCanvas(image);
	}
	var context = renderer.context;
	context.globalAlpha = 1;
	renderer.setTransform(bitmapData.__renderTransform,context);
	context.drawImage(image.get_src(),0,0,image.width,image.height);
}
CanvasBitmapData.renderDrawableMask = function(bitmapData,renderer) {
}


// Export

exports.default = CanvasBitmapData;