// Class: openfl.display.Timeline

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../hxClasses_stub").default;
var $hxEnums = require("./../../hxEnums_stub").default;
var $import = require("./../../import_stub").default;
function haxe_ds_IntMap() {return require("./../../haxe/ds/IntMap");}
function Std() {return require("./../../Std");}
function js__$Boot_HaxeError() {return require("./../../js/_Boot/HaxeError");}
function openfl_errors_ArgumentError() {return require("./../../openfl/errors/ArgumentError");}
function Type() {return require("./../../Type");}

// Constructor

var Timeline = function() {
	this.__framesLoaded = 1;
	this.__totalFrames = 1;
	this.__currentLabels = [];
	this.__currentFrame = 1;
	this.__lastFrameScriptEval = -1;
	this.__lastFrameUpdate = -1;
}

// Meta

Timeline.__name__ = "openfl.display.Timeline";
Timeline.__isInterface__ = false;
Timeline.prototype = {
	attachMovieClip: function(movieClip) {
	},
	enterFrame: function(frame) {
	},
	__addFrameScript: function(index,method) {
		if(index < 0) {
			return;
		}
		var frame = index + 1;
		if(method != null) {
			if(this.__frameScripts == null) {
				this.__frameScripts = new (haxe_ds_IntMap().default)();
			}
			this.__frameScripts.set(frame,function(scope) {
				method();
			});
		} else if(this.__frameScripts != null) {
			this.__frameScripts.remove(frame);
		}
	},
	__attachMovieClip: function(movieClip) {
		this.__scope = movieClip;
		this.__totalFrames = 0;
		this.__framesLoaded = 0;
		if(this.scenes != null && this.scenes.length > 0) {
			var _g = 0;
			var _g1 = this.scenes;
			while(_g < _g1.length) {
				var scene = _g1[_g];
				++_g;
				this.__totalFrames += scene.numFrames;
				this.__framesLoaded += scene.numFrames;
				if(scene.labels != null) {
					this.__currentLabels = this.__currentLabels.concat(scene.labels);
				}
			}
			this.__currentScene = this.scenes[0];
		}
		if(this.scripts != null && this.scripts.length > 0) {
			this.__frameScripts = new (haxe_ds_IntMap().default)();
			var _g2 = 0;
			var _g11 = this.scripts;
			while(_g2 < _g11.length) {
				var script = _g11[_g2];
				++_g2;
				this.__frameScripts.set(script.frame,script.script);
			}
		}
		this.attachMovieClip(movieClip);
	},
	__enterFrame: function(deltaTime) {
		if(this.__isPlaying) {
			var nextFrame = this.__getNextFrame(deltaTime);
			if(this.__lastFrameScriptEval == nextFrame) {
				return;
			}
			if(this.__frameScripts != null) {
				if(nextFrame < this.__currentFrame) {
					if(!this.__evaluateFrameScripts(this.__totalFrames)) {
						return;
					}
					this.__currentFrame = 1;
				}
				if(!this.__evaluateFrameScripts(nextFrame)) {
					return;
				}
			} else {
				this.__currentFrame = nextFrame;
			}
		}
		this.__updateSymbol(this.__currentFrame);
	},
	__evaluateFrameScripts: function(advanceToFrame) {
		if(this.__frameScripts == null) {
			return true;
		}
		var _g = this.__currentFrame;
		var _g1 = advanceToFrame + 1;
		while(_g < _g1) {
			var frame = _g++;
			if(frame == this.__lastFrameScriptEval) {
				continue;
			}
			this.__lastFrameScriptEval = frame;
			this.__currentFrame = frame;
			if(this.__frameScripts.exists(frame)) {
				this.__updateSymbol(frame);
				var script = this.__frameScripts.get(frame);
				script(this.__scope);
				if(this.__currentFrame != frame) {
					return false;
				}
			}
			if(!this.__isPlaying) {
				return false;
			}
		}
		return true;
	},
	__getNextFrame: function(deltaTime) {
		var nextFrame = 0;
		if(this.frameRate != null) {
			this.__timeElapsed += deltaTime;
			nextFrame = this.__currentFrame + Math.floor(this.__timeElapsed / this.__frameTime);
			if(nextFrame < 1) {
				nextFrame = 1;
			}
			if(nextFrame > this.__totalFrames) {
				nextFrame = Math.floor((nextFrame - 1) % this.__totalFrames) + 1;
			}
			this.__timeElapsed %= this.__frameTime;
		} else {
			nextFrame = this.__currentFrame + 1;
			if(nextFrame > this.__totalFrames) {
				nextFrame = 1;
			}
		}
		return nextFrame;
	},
	__goto: function(frame) {
		if(frame < 1) {
			frame = 1;
		} else if(frame > this.__totalFrames) {
			frame = this.__totalFrames;
		}
		this.__lastFrameScriptEval = -1;
		this.__currentFrame = frame;
		this.__updateSymbol(this.__currentFrame);
		this.__evaluateFrameScripts(this.__currentFrame);
	},
	__gotoAndPlay: function(frame,scene) {
		this.__play();
		this.__goto(this.__resolveFrameReference(frame));
	},
	__gotoAndStop: function(frame,scene) {
		this.__stop();
		this.__goto(this.__resolveFrameReference(frame));
	},
	__nextFrame: function() {
		this.__stop();
		this.__goto(this.__currentFrame + 1);
	},
	__nextScene: function() {
	},
	__play: function() {
		if(this.__isPlaying || this.__totalFrames < 2) {
			return;
		}
		this.__isPlaying = true;
		if(this.frameRate != null) {
			this.__frameTime = (Std().default).int(1000 / this.frameRate);
			this.__timeElapsed = 0;
		}
	},
	__prevFrame: function() {
		this.__stop();
		this.__goto(this.__currentFrame - 1);
	},
	__prevScene: function() {
	},
	__stop: function() {
		this.__isPlaying = false;
	},
	__resolveFrameReference: function(frame) {
		if(typeof(frame) == "number" && ((frame | 0) === frame)) {
			return frame;
		} else if(typeof(frame) == "string") {
			var label = frame;
			var _g = 0;
			var _g1 = this.__currentLabels;
			while(_g < _g1.length) {
				var frameLabel = _g1[_g];
				++_g;
				if(frameLabel.name == label) {
					return frameLabel.frame;
				}
			}
			throw new (js__$Boot_HaxeError().default)(new (openfl_errors_ArgumentError().default)("Error #2109: Frame label " + label + " not found in scene."));
		} else {
			throw new (js__$Boot_HaxeError().default)("Invalid type for frame " + (Type().default).getClassName(frame));
		}
	},
	__updateFrameLabel: function() {
		this.__currentLabel = null;
		this.__currentFrameLabel = null;
		var _g = 0;
		var _g1 = this.__currentLabels;
		while(_g < _g1.length) {
			var label = _g1[_g];
			++_g;
			if(label.frame < this.__currentFrame) {
				this.__currentLabel = label.name;
			} else if(label.frame == this.__currentFrame) {
				this.__currentLabel = label.name;
				this.__currentFrameLabel = label.name;
			} else {
				break;
			}
		}
	},
	__updateSymbol: function(targetFrame) {
		if(this.__currentFrame != this.__lastFrameUpdate) {
			this.__updateFrameLabel();
			this.enterFrame(targetFrame);
			this.__lastFrameUpdate = this.__currentFrame;
		}
	}
};
Timeline.prototype.__class__ = Timeline.prototype.constructor = $hxClasses["openfl.display.Timeline"] = Timeline;

// Init



// Statics




// Export

exports.default = Timeline;