// Class: openfl.display._internal.Context3DSimpleButton

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;

// Constructor

var Context3DSimpleButton = function(){}

// Meta

Context3DSimpleButton.__name__ = "openfl.display._internal.Context3DSimpleButton";
Context3DSimpleButton.__isInterface__ = false;
Context3DSimpleButton.prototype = {
	
};
Context3DSimpleButton.prototype.__class__ = Context3DSimpleButton.prototype.constructor = $hxClasses["openfl.display._internal.Context3DSimpleButton"] = Context3DSimpleButton;

// Init



// Statics

Context3DSimpleButton.renderDrawable = function(simpleButton,renderer) {
	if(!simpleButton.__renderable || simpleButton.__worldAlpha <= 0 || simpleButton.__currentState == null) {
		return;
	}
	renderer.__pushMaskObject(simpleButton);
	renderer.__renderDrawable(simpleButton.__currentState);
	renderer.__popMaskObject(simpleButton);
	renderer.__renderEvent(simpleButton);
}
Context3DSimpleButton.renderDrawableMask = function(simpleButton,renderer) {
	if(simpleButton.__currentState == null) {
		return;
	}
	renderer.__renderDrawableMask(simpleButton.__currentState);
}


// Export

exports.default = Context3DSimpleButton;