// Class: openfl.display._internal.CanvasDisplayObjectContainer

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
function openfl_display__$internal_CanvasDisplayObject() {return require("./../../../openfl/display/_internal/CanvasDisplayObject");}
function openfl_display__$internal_CanvasGraphics() {return require("./../../../openfl/display/_internal/CanvasGraphics");}

// Constructor

var CanvasDisplayObjectContainer = function(){}

// Meta

CanvasDisplayObjectContainer.__name__ = "openfl.display._internal.CanvasDisplayObjectContainer";
CanvasDisplayObjectContainer.__isInterface__ = false;
CanvasDisplayObjectContainer.prototype = {
	
};
CanvasDisplayObjectContainer.prototype.__class__ = CanvasDisplayObjectContainer.prototype.constructor = $hxClasses["openfl.display._internal.CanvasDisplayObjectContainer"] = CanvasDisplayObjectContainer;

// Init



// Statics

CanvasDisplayObjectContainer.renderDrawable = function(displayObjectContainer,renderer) {
	displayObjectContainer.__cleanupRemovedChildren();
	if(!displayObjectContainer.__renderable || displayObjectContainer.__worldAlpha <= 0 || displayObjectContainer.get_mask() != null && (displayObjectContainer.get_mask().get_width() <= 0 || displayObjectContainer.get_mask().get_height() <= 0)) {
		return;
	}
	(openfl_display__$internal_CanvasDisplayObject().default).renderDrawable(displayObjectContainer,renderer);
	if(displayObjectContainer.__cacheBitmap != null && !displayObjectContainer.__isCacheBitmapRender) {
		return;
	}
	renderer.__pushMaskObject(displayObjectContainer);
	if(renderer.__stage != null) {
		var _g = 0;
		var _g1 = displayObjectContainer.__children;
		while(_g < _g1.length) {
			var child = _g1[_g];
			++_g;
			renderer.__renderDrawable(child);
			child.__renderDirty = false;
		}
		displayObjectContainer.__renderDirty = false;
	} else {
		var _g2 = 0;
		var _g11 = displayObjectContainer.__children;
		while(_g2 < _g11.length) {
			var child1 = _g11[_g2];
			++_g2;
			renderer.__renderDrawable(child1);
		}
	}
	renderer.__popMaskObject(displayObjectContainer);
}
CanvasDisplayObjectContainer.renderDrawableMask = function(displayObjectContainer,renderer) {
	displayObjectContainer.__cleanupRemovedChildren();
	if(displayObjectContainer.__graphics != null) {
		(openfl_display__$internal_CanvasGraphics().default).renderMask(displayObjectContainer.__graphics,renderer);
	}
	var _g = 0;
	var _g1 = displayObjectContainer.__children;
	while(_g < _g1.length) {
		var child = _g1[_g];
		++_g;
		renderer.__renderDrawableMask(child);
	}
}


// Export

exports.default = CanvasDisplayObjectContainer;