// Class: openfl.display.FrameScript

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../hxClasses_stub").default;
var $hxEnums = require("./../../hxEnums_stub").default;

// Constructor

var FrameScript = function(script,frame) {
	this.script = script;
	this.frame = frame;
}

// Meta

FrameScript.__name__ = "openfl.display.FrameScript";
FrameScript.__isInterface__ = false;
FrameScript.prototype = {
	
};
FrameScript.prototype.__class__ = FrameScript.prototype.constructor = $hxClasses["openfl.display.FrameScript"] = FrameScript;

// Init



// Statics




// Export

exports.default = FrameScript;