// Class: openfl.display._internal.Context3DBitmapData

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;

// Constructor

var Context3DBitmapData = function(){}

// Meta

Context3DBitmapData.__name__ = "openfl.display._internal.Context3DBitmapData";
Context3DBitmapData.__isInterface__ = false;
Context3DBitmapData.prototype = {
	
};
Context3DBitmapData.prototype.__class__ = Context3DBitmapData.prototype.constructor = $hxClasses["openfl.display._internal.Context3DBitmapData"] = Context3DBitmapData;

// Init



// Statics

Context3DBitmapData.renderDrawable = function(bitmapData,renderer) {
	var context = renderer.__context3D;
	var gl = context.gl;
	renderer.__setBlendMode("normal");
	var shader = renderer.__defaultDisplayShader;
	renderer.setShader(shader);
	renderer.applyBitmapData(bitmapData,renderer.__upscaled);
	renderer.applyMatrix(renderer.__getMatrix(bitmapData.__worldTransform,"auto"));
	renderer.applyAlpha(bitmapData.__worldAlpha);
	renderer.applyColorTransform(bitmapData.__worldColorTransform);
	renderer.updateShader();
	var vertexBuffer = bitmapData.getVertexBuffer(context);
	if(shader.__position != null) {
		context.setVertexBufferAt(shader.__position.index,vertexBuffer,0,"float3");
	}
	if(shader.__textureCoord != null) {
		context.setVertexBufferAt(shader.__textureCoord.index,vertexBuffer,3,"float2");
	}
	var indexBuffer = bitmapData.getIndexBuffer(context);
	context.drawTriangles(indexBuffer);
	renderer.__clearShader();
}
Context3DBitmapData.renderDrawableMask = function(bitmapData,renderer) {
	var context = renderer.__context3D;
	var gl = context.gl;
	var shader = renderer.__maskShader;
	renderer.setShader(shader);
	renderer.applyBitmapData(bitmapData,renderer.__upscaled);
	renderer.applyMatrix(renderer.__getMatrix(bitmapData.__worldTransform,"auto"));
	renderer.updateShader();
	var vertexBuffer = bitmapData.getVertexBuffer(context);
	if(shader.__position != null) {
		context.setVertexBufferAt(shader.__position.index,vertexBuffer,0,"float3");
	}
	if(shader.__textureCoord != null) {
		context.setVertexBufferAt(shader.__textureCoord.index,vertexBuffer,3,"float2");
	}
	var indexBuffer = bitmapData.getIndexBuffer(context);
	context.drawTriangles(indexBuffer);
	renderer.__clearShader();
}


// Export

exports.default = Context3DBitmapData;