// Class: openfl.display.Scene

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../hxClasses_stub").default;
var $hxEnums = require("./../../hxEnums_stub").default;

// Constructor

var Scene = function(name,labels,numFrames) {
	this.name = name;
	this.labels = labels;
	this.numFrames = numFrames;
}

// Meta

Scene.__name__ = "openfl.display.Scene";
Scene.__isInterface__ = false;
Scene.prototype = {
	
};
Scene.prototype.__class__ = Scene.prototype.constructor = $hxClasses["openfl.display.Scene"] = Scene;

// Init



// Statics




// Export

exports.default = Scene;