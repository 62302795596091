Object.defineProperty (module.exports, "__esModule", { value: true });
module.exports.Context3DCompareMode = module.exports.default = {
	ALWAYS: "always",
	EQUAL: "equal",
	GREATER: "greater",
	GREATER_EQUAL: "greaterEqual",
	LESS: "less",
	LESS_EQUAL: "lessEqual",
	NEVER: "never",
	NOT_EQUAL: "notEqual"
};