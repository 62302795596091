// Class: swf.exporters.swflite.DynamicTextSymbol

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
var $extend = require("./../../../extend_stub").default;
function swf_exporters_swflite_SWFSymbol() {return require("./../../../swf/exporters/swflite/SWFSymbol");}
function openfl_text_TextField() {return require("./../../../openfl/text/TextField");}
function openfl_text_TextFormat() {return require("./../../../openfl/text/TextFormat");}
function openfl_text_Font() {return require("./../../../openfl/text/Font");}
function EReg() {return require("./../../../EReg");}
function HxOverrides() {return require("./../../../HxOverrides");}
function lime_utils_Log() {return require("./../../../lime/utils/Log");}
function Std() {return require("./../../../Std");}

// Constructor

var DynamicTextSymbol = function() {
	(swf_exporters_swflite_SWFSymbol().default).call(this);
}

// Meta

DynamicTextSymbol.__name__ = "swf.exporters.swflite.DynamicTextSymbol";
DynamicTextSymbol.__isInterface__ = false;
DynamicTextSymbol.__super__ = (swf_exporters_swflite_SWFSymbol().default);
DynamicTextSymbol.prototype = $extend((swf_exporters_swflite_SWFSymbol().default).prototype, {
	__createObject: function(swf1) {
		var textField = new (openfl_text_TextField().default)();
		textField.set_width(this.width);
		textField.set_height(this.height);
		textField.__offsetX = this.x;
		textField.__offsetY = this.y;
		textField.set_multiline(this.multiline);
		textField.set_wordWrap(this.wordWrap);
		textField.set_displayAsPassword(this.password);
		if(this.border) {
			textField.set_border(true);
			textField.set_background(true);
		}
		textField.set_selectable(this.selectable);
		if(this.input) {
			textField.set_type("input");
		}
		var format = new (openfl_text_TextFormat().default)();
		if(this.color != null) {
			format.color = this.color & 16777215;
		}
		format.size = Math.round(this.fontHeight / 20);
		var font = swf1.symbols.get(this.fontID);
		if(font != null) {
			format.__ascent = font.ascent / 20 / 1024;
			format.__descent = font.descent / 20 / 1024;
		}
		format.font = this.fontName;
		var found = false;
		var _g = format.font;
		if(_g == null) {
			found = true;
		} else {
			switch(_g) {
			case "":case "_sans":case "_serif":case "_typewriter":
				found = true;
				break;
			default:
				var _g1 = 0;
				var _g11 = (openfl_text_Font().default).enumerateFonts();
				while(_g1 < _g11.length) {
					var font1 = _g11[_g1];
					++_g1;
					if(font1.get_fontName() == format.font) {
						found = true;
						break;
					}
				}
			}
		}
		if(!found) {
			var alpha = new (EReg().default)("[^a-zA-Z]+","g");
			var _g12 = 0;
			var _g2 = (openfl_text_Font().default).enumerateFonts();
			while(_g12 < _g2.length) {
				var font2 = _g2[_g12];
				++_g12;
				if((HxOverrides().default).substr(alpha.replace(font2.get_fontName(),""),0,this.fontName.length) == this.fontName) {
					format.font = font2.get_fontName();
					found = true;
					break;
				}
			}
		}
		if(found) {
			textField.set_embedFonts(true);
		} else if(!(openfl_text_TextField().default).__missingFontWarning.exists(format.font)) {
			(openfl_text_TextField().default).__missingFontWarning.set(format.font,true);
			(lime_utils_Log().default).warn("Could not find required font \"" + format.font + "\", it has not been embedded",{ fileName : "../node_modules/swf/src/swf/exporters/swflite/DynamicTextSymbol.hx", lineNumber : 143, className : "swf.exporters.swflite.DynamicTextSymbol", methodName : "__createObject"});
		}
		if(this.align != null) {
			if(this.align == "center") {
				format.align = "center";
			} else if(this.align == "right") {
				format.align = "right";
			} else if(this.align == "justify") {
				format.align = "justify";
			}
			format.leftMargin = (Std().default).int(this.leftMargin / 20);
			format.rightMargin = (Std().default).int(this.rightMargin / 20);
			format.indent = (Std().default).int(this.indent / 20);
			format.leading = (Std().default).int(this.leading / 20);
		}
		textField.set_defaultTextFormat(format);
		if(this.text != null) {
			if(this.html) {
				textField.set_htmlText(this.text);
			} else {
				textField.set_text(this.text);
			}
		}
		return textField;
	}
});
DynamicTextSymbol.prototype.__class__ = DynamicTextSymbol.prototype.constructor = $hxClasses["swf.exporters.swflite.DynamicTextSymbol"] = DynamicTextSymbol;

// Init



// Statics




// Export

exports.default = DynamicTextSymbol;