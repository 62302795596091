// Class: openfl.filters._GlowFilter.BlurAlphaShader

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
var $extend = require("./../../../extend_stub").default;
function openfl_filters_BitmapFilterShader() {return require("./../../../openfl/filters/BitmapFilterShader");}

// Constructor

var BlurAlphaShader = function() {
	if(this.__glFragmentSource == null) {
		this.__glFragmentSource = "\r\n\t\tuniform sampler2D openfl_Texture;\r\n\t\tuniform vec4 uColor;\r\n\t\tuniform float uStrength;\r\n\t\tvarying vec2 vTexCoord;\r\n\t\tvarying vec2 vBlurCoords[6];\r\n\r\n\t\tvoid main(void)\r\n\t\t{\r\n            vec4 texel = texture2D(openfl_Texture, vTexCoord);\r\n\r\n            vec3 contributions = vec3(0.00443, 0.05399, 0.24197);\r\n            vec3 top = vec3(\r\n                texture2D(openfl_Texture, vBlurCoords[0]).a,\r\n                texture2D(openfl_Texture, vBlurCoords[1]).a,\r\n                texture2D(openfl_Texture, vBlurCoords[2]).a\r\n            );\r\n            vec3 bottom = vec3(\r\n                texture2D(openfl_Texture, vBlurCoords[3]).a,\r\n                texture2D(openfl_Texture, vBlurCoords[4]).a,\r\n                texture2D(openfl_Texture, vBlurCoords[5]).a\r\n            );\r\n\r\n            float a = texel.a * 0.39894;\r\n\t\t\ta += dot(top, contributions.xyz);\r\n            a += dot(bottom, contributions.zyx);\r\n\r\n\t\t\tgl_FragColor = uColor * clamp(a * uStrength, 0.0, 1.0);\r\n\t\t}\r\n\t";
	}
	if(this.__glVertexSource == null) {
		this.__glVertexSource = "\r\n\t\tattribute vec4 openfl_Position;\r\n\t\tattribute vec2 openfl_TextureCoord;\r\n\r\n\t\tuniform mat4 openfl_Matrix;\r\n\t\tuniform vec2 openfl_TextureSize;\r\n\r\n\t\tuniform vec2 uRadius;\r\n\t\tvarying vec2 vTexCoord;\r\n\t\tvarying vec2 vBlurCoords[6];\r\n\r\n\t\tvoid main(void) {\r\n\r\n\t\t\tgl_Position = openfl_Matrix * openfl_Position;\r\n\t\t\tvTexCoord = openfl_TextureCoord;\r\n\r\n\t\t\tvec3 offset = vec3(0.5, 0.75, 1.0);\r\n\t\t\tvec2 r = uRadius / openfl_TextureSize;\r\n\t\t\tvBlurCoords[0] = openfl_TextureCoord - r * offset.z;\r\n\t\t\tvBlurCoords[1] = openfl_TextureCoord - r * offset.y;\r\n\t\t\tvBlurCoords[2] = openfl_TextureCoord - r * offset.x;\r\n\t\t\tvBlurCoords[3] = openfl_TextureCoord + r * offset.x;\r\n\t\t\tvBlurCoords[4] = openfl_TextureCoord + r * offset.y;\r\n\t\t\tvBlurCoords[5] = openfl_TextureCoord + r * offset.z;\r\n\t\t}\r\n\t";
	}
	(openfl_filters_BitmapFilterShader().default).call(this);
	this.uRadius.value = [0,0];
	this.uColor.value = [0,0,0,0];
	this.uStrength.value = [1];
	this.__isGenerated = true;
	this.__initGL();
}

// Meta

BlurAlphaShader.__name__ = "openfl.filters._GlowFilter.BlurAlphaShader";
BlurAlphaShader.__isInterface__ = false;
BlurAlphaShader.__super__ = (openfl_filters_BitmapFilterShader().default);
BlurAlphaShader.prototype = $extend((openfl_filters_BitmapFilterShader().default).prototype, {
	
});
BlurAlphaShader.prototype.__class__ = BlurAlphaShader.prototype.constructor = $hxClasses["openfl.filters._GlowFilter.BlurAlphaShader"] = BlurAlphaShader;

// Init



// Statics




// Export

exports.default = BlurAlphaShader;