// Class: swf.exporters.swflite.FontSymbol

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
var $extend = require("./../../../extend_stub").default;
function swf_exporters_swflite_SWFSymbol() {return require("./../../../swf/exporters/swflite/SWFSymbol");}

// Constructor

var FontSymbol = function() {
	(swf_exporters_swflite_SWFSymbol().default).call(this);
}

// Meta

FontSymbol.__name__ = "swf.exporters.swflite.FontSymbol";
FontSymbol.__isInterface__ = false;
FontSymbol.__super__ = (swf_exporters_swflite_SWFSymbol().default);
FontSymbol.prototype = $extend((swf_exporters_swflite_SWFSymbol().default).prototype, {
	
});
FontSymbol.prototype.__class__ = FontSymbol.prototype.constructor = $hxClasses["swf.exporters.swflite.FontSymbol"] = FontSymbol;

// Init



// Statics




// Export

exports.default = FontSymbol;