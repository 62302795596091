// Class: openfl.display3D._internal._AGALConverter.RegisterMapEntry

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../../hxClasses_stub").default;
var $hxEnums = require("./../../../../hxEnums_stub").default;

// Constructor

var RegisterMapEntry = function() {
}

// Meta

RegisterMapEntry.__name__ = "openfl.display3D._internal._AGALConverter.RegisterMapEntry";
RegisterMapEntry.__isInterface__ = false;
RegisterMapEntry.prototype = {
	
};
RegisterMapEntry.prototype.__class__ = RegisterMapEntry.prototype.constructor = $hxClasses["openfl.display3D._internal._AGALConverter.RegisterMapEntry"] = RegisterMapEntry;

// Init



// Statics




// Export

exports.default = RegisterMapEntry;