// Class: swf.exporters.swflite.ShapeSymbol

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
var $extend = require("./../../../extend_stub").default;
function swf_exporters_swflite_SWFSymbol() {return require("./../../../swf/exporters/swflite/SWFSymbol");}
function openfl_display_Shape() {return require("./../../../openfl/display/Shape");}
function openfl_display_BitmapData() {return require("./../../../openfl/display/BitmapData");}

// Constructor

var ShapeSymbol = function() {
	(swf_exporters_swflite_SWFSymbol().default).call(this);
}

// Meta

ShapeSymbol.__name__ = "swf.exporters.swflite.ShapeSymbol";
ShapeSymbol.__isInterface__ = false;
ShapeSymbol.__super__ = (swf_exporters_swflite_SWFSymbol().default);
ShapeSymbol.prototype = $extend((swf_exporters_swflite_SWFSymbol().default).prototype, {
	__createObject: function(swf1) {
		var shape = new (openfl_display_Shape().default)();
		var graphics = shape.get_graphics();
		if(this.rendered != null) {
			graphics.copyFrom(this.rendered.get_graphics());
			return shape;
		}
		var _g = 0;
		var _g1 = this.commands;
		while(_g < _g1.length) {
			var command = _g1[_g];
			++_g;
			switch(command._hx_index) {
			case 0:
				var smooth = command.smooth;
				var repeat = command.repeat;
				var matrix = command.matrix;
				var bitmapID = command.bitmap;
				var bitmapSymbol = swf1.symbols.get(bitmapID);
				var bitmap = swf1.library.getImage(bitmapSymbol.path);
				if(bitmap != null) {
					graphics.beginBitmapFill((openfl_display_BitmapData().default).fromImage(bitmap),matrix,repeat,smooth);
				}
				break;
			case 1:
				var alpha = command.alpha;
				var color = command.color;
				graphics.beginFill(color,alpha);
				break;
			case 2:
				var focalPointRatio = command.focalPointRatio;
				var interpolationMethod = command.interpolationMethod;
				var spreadMethod = command.spreadMethod;
				var matrix1 = command.matrix;
				var ratios = command.ratios;
				var alphas = command.alphas;
				var colors = command.colors;
				var fillType = command.fillType;
				graphics.beginGradientFill(fillType,colors,alphas,ratios,matrix1,spreadMethod,interpolationMethod,focalPointRatio);
				break;
			case 3:
				var anchorY = command.anchorY;
				var anchorX = command.anchorX;
				var controlY = command.controlY;
				var controlX = command.controlX;
				graphics.curveTo(controlX,controlY,anchorX,anchorY);
				break;
			case 4:
				graphics.endFill();
				break;
			case 5:
				var miterLimit = command.miterLimit;
				var joints = command.joints;
				var caps = command.caps;
				var scaleMode = command.scaleMode;
				var pixelHinting = command.pixelHinting;
				var alpha1 = command.alpha;
				var color1 = command.color;
				var thickness = command.thickness;
				if(thickness != null) {
					graphics.lineStyle(thickness,color1,alpha1,pixelHinting,scaleMode,caps,joints,miterLimit);
				} else {
					graphics.lineStyle();
				}
				break;
			case 6:
				var y = command.y;
				var x = command.x;
				graphics.lineTo(x,y);
				break;
			case 7:
				var y1 = command.y;
				var x1 = command.x;
				graphics.moveTo(x1,y1);
				break;
			}
		}
		this.commands = null;
		this.rendered = new (openfl_display_Shape().default)();
		this.rendered.get_graphics().copyFrom(shape.get_graphics());
		return shape;
	}
});
ShapeSymbol.prototype.__class__ = ShapeSymbol.prototype.constructor = $hxClasses["swf.exporters.swflite.ShapeSymbol"] = ShapeSymbol;

// Init



// Statics




// Export

exports.default = ShapeSymbol;