// Class: openfl.display._internal.DOMDisplayObject

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
function openfl_display__$internal_DOMBitmap() {return require("./../../../openfl/display/_internal/DOMBitmap");}
function openfl_display__$internal_DOMShape() {return require("./../../../openfl/display/_internal/DOMShape");}

// Constructor

var DOMDisplayObject = function(){}

// Meta

DOMDisplayObject.__name__ = "openfl.display._internal.DOMDisplayObject";
DOMDisplayObject.__isInterface__ = false;
DOMDisplayObject.prototype = {
	
};
DOMDisplayObject.prototype.__class__ = DOMDisplayObject.prototype.constructor = $hxClasses["openfl.display._internal.DOMDisplayObject"] = DOMDisplayObject;

// Init



// Statics

DOMDisplayObject.clear = function(displayObject,renderer) {
	if(displayObject.__cacheBitmap != null) {
		(openfl_display__$internal_DOMBitmap().default).clear(displayObject.__cacheBitmap,renderer);
	}
	(openfl_display__$internal_DOMShape().default).clear(displayObject,renderer);
}
DOMDisplayObject.render = function(displayObject,renderer) {
	var tmp = displayObject.opaqueBackground != null && !displayObject.__isCacheBitmapRender && displayObject.get_width() > 0 && displayObject.get_height() > 0;
	(openfl_display__$internal_DOMShape().default).render(displayObject,renderer);
}
DOMDisplayObject.renderDrawable = function(displayObject,renderer) {
	renderer.__updateCacheBitmap(displayObject,false);
	if(displayObject.__cacheBitmap != null && !displayObject.__isCacheBitmapRender) {
		renderer.__renderDrawableClear(displayObject);
		displayObject.__cacheBitmap.stage = displayObject.stage;
		(openfl_display__$internal_DOMBitmap().default).render(displayObject.__cacheBitmap,renderer);
	} else {
		DOMDisplayObject.render(displayObject,renderer);
	}
	renderer.__renderEvent(displayObject);
}
DOMDisplayObject.renderDrawableClear = function(displayObject,renderer) {
	DOMDisplayObject.clear(displayObject,renderer);
}
DOMDisplayObject.__meta__ = { obj : { SuppressWarnings : ["checkstyle:FieldDocComment"]}}

// Export

exports.default = DOMDisplayObject;