// Class: swf.exporters.swflite.BitmapSymbol

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
var $extend = require("./../../../extend_stub").default;
function swf_exporters_swflite_SWFSymbol() {return require("./../../../swf/exporters/swflite/SWFSymbol");}
function openfl_display_Bitmap() {return require("./../../../openfl/display/Bitmap");}
function openfl_display_BitmapData() {return require("./../../../openfl/display/BitmapData");}

// Constructor

var BitmapSymbol = function() {
	(swf_exporters_swflite_SWFSymbol().default).call(this);
}

// Meta

BitmapSymbol.__name__ = "swf.exporters.swflite.BitmapSymbol";
BitmapSymbol.__isInterface__ = false;
BitmapSymbol.__super__ = (swf_exporters_swflite_SWFSymbol().default);
BitmapSymbol.prototype = $extend((swf_exporters_swflite_SWFSymbol().default).prototype, {
	__createObject: function(swf1) {
		return new (openfl_display_Bitmap().default)((openfl_display_BitmapData().default).fromImage(swf1.library.getImage(this.path)),"auto",this.smooth != false);
	}
});
BitmapSymbol.prototype.__class__ = BitmapSymbol.prototype.constructor = $hxClasses["swf.exporters.swflite.BitmapSymbol"] = BitmapSymbol;

// Init



// Statics




// Export

exports.default = BitmapSymbol;