// Enum: openfl.display3D._internal._AGALConverter.ProgramType

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

// Imports

var $estr = require("./../../../../estr_stub").default;
var $hxEnums = require("./../../../../hxEnums_stub").default;

// Definition

var ProgramType = $hxEnums["openfl.display3D._internal._AGALConverter.ProgramType"] = { __ename__ : "openfl.display3D._internal._AGALConverter.ProgramType", __constructs__ : ["VERTEX","FRAGMENT"]
  ,VERTEX: {_hx_index:0,__enum__:"openfl.display3D._internal._AGALConverter.ProgramType",toString:$estr}
  ,FRAGMENT: {_hx_index:1,__enum__:"openfl.display3D._internal._AGALConverter.ProgramType",toString:$estr}
};

exports.default = ProgramType;