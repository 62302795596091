// Class: openfl.display.DisplayObjectRenderer

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../hxClasses_stub").default;
var $hxEnums = require("./../../hxEnums_stub").default;
var $import = require("./../../import_stub").default;
var $extend = require("./../../extend_stub").default;
function openfl_events_EventDispatcher() {return require("./../../openfl/events/EventDispatcher");}
function openfl_display__$internal_Context3DGraphics() {return require("./../../openfl/display/_internal/Context3DGraphics");}
function openfl_geom_ColorTransform() {return require("./../../openfl/geom/ColorTransform");}
function openfl_geom_Matrix() {return require("./../../openfl/geom/Matrix");}
function openfl_geom_Rectangle() {return require("./../../openfl/geom/Rectangle");}
function openfl_display_BitmapData() {return require("./../../openfl/display/BitmapData");}
function openfl_display_Bitmap() {return require("./../../openfl/display/Bitmap");}
function openfl_display_OpenGLRenderer() {return require("./../../openfl/display/OpenGLRenderer");}
function js_Boot() {return require("./../../js/Boot");}
function lime__$internal_graphics_ImageCanvasUtil() {return require("./../../lime/_internal/graphics/ImageCanvasUtil");}
function openfl_display_CanvasRenderer() {return require("./../../openfl/display/CanvasRenderer");}
function openfl_geom_Point() {return require("./../../openfl/geom/Point");}

// Constructor

var DisplayObjectRenderer = function() {
	(openfl_events_EventDispatcher().default).call(this);
	this.__allowSmoothing = true;
	this.__tempColorTransform = new (openfl_geom_ColorTransform().default)();
	this.__worldAlpha = 1;
}

// Meta

DisplayObjectRenderer.__name__ = "openfl.display.DisplayObjectRenderer";
DisplayObjectRenderer.__isInterface__ = false;
DisplayObjectRenderer.__super__ = (openfl_events_EventDispatcher().default);
DisplayObjectRenderer.prototype = $extend((openfl_events_EventDispatcher().default).prototype, {
	__clear: function() {
	},
	__getAlpha: function(value) {
		return value * this.__worldAlpha;
	},
	__getColorTransform: function(value) {
		if(this.__worldColorTransform != null) {
			this.__tempColorTransform.__copyFrom(this.__worldColorTransform);
			this.__tempColorTransform.__combine(value);
			return this.__tempColorTransform;
		} else {
			return value;
		}
	},
	__popMask: function() {
	},
	__popMaskObject: function(object,handleScrollRect) {
		if(handleScrollRect == null) {
			handleScrollRect = true;
		}
	},
	__popMaskRect: function() {
	},
	__pushMask: function(mask) {
	},
	__pushMaskObject: function(object,handleScrollRect) {
		if(handleScrollRect == null) {
			handleScrollRect = true;
		}
	},
	__pushMaskRect: function(rect,transform) {
	},
	__render: function(object) {
	},
	__renderEvent: function(displayObject) {
		var renderer = this;
		if(displayObject.__customRenderEvent != null && displayObject.__renderable) {
			displayObject.__customRenderEvent.allowSmoothing = renderer.__allowSmoothing;
			displayObject.__customRenderEvent.objectMatrix.copyFrom(displayObject.__renderTransform);
			displayObject.__customRenderEvent.objectColorTransform.__copyFrom(displayObject.__worldColorTransform);
			displayObject.__customRenderEvent.renderer = renderer;
			switch(renderer.__type) {
			case "cairo":
				displayObject.__customRenderEvent.type = "renderCairo";
				break;
			case "canvas":
				displayObject.__customRenderEvent.type = "renderCanvas";
				break;
			case "dom":
				if(displayObject.stage != null && displayObject.__worldVisible) {
					displayObject.__customRenderEvent.type = "renderDOM";
				} else {
					displayObject.__customRenderEvent.type = "clearDOM";
				}
				break;
			case "opengl":
				if(!renderer.__cleared) {
					renderer.__clear();
				}
				var renderer1 = renderer;
				renderer1.setShader(displayObject.__worldShader);
				renderer1.__context3D.__flushGL();
				displayObject.__customRenderEvent.type = "renderOpenGL";
				break;
			default:
				return;
			}
			renderer.__setBlendMode(displayObject.__worldBlendMode);
			renderer.__pushMaskObject(displayObject);
			displayObject.dispatchEvent(displayObject.__customRenderEvent);
			renderer.__popMaskObject(displayObject);
			if(renderer.__type == "opengl") {
				var renderer2 = renderer;
				renderer2.setViewport();
			}
		}
	},
	__resize: function(width,height) {
	},
	__setBlendMode: function(value) {
	},
	__shouldCacheHardware: function(displayObject,value) {
		if(displayObject == null) {
			return null;
		}
		switch(displayObject.__drawableType) {
		case 4:case 5:
			if(value == true) {
				return true;
			}
			value = this.__shouldCacheHardware_DisplayObject(displayObject,value);
			if(value == true) {
				return true;
			}
			if(displayObject.__children != null) {
				var _g = 0;
				var _g1 = displayObject.__children;
				while(_g < _g1.length) {
					var child = _g1[_g];
					++_g;
					value = this.__shouldCacheHardware_DisplayObject(child,value);
					if(value == true) {
						return true;
					}
				}
			}
			return value;
		case 7:
			if(value == true) {
				return true;
			} else {
				return false;
			}
			break;
		case 9:
			return true;
		default:
			return this.__shouldCacheHardware_DisplayObject(displayObject,value);
		}
	},
	__shouldCacheHardware_DisplayObject: function(displayObject,value) {
		if(value == true || displayObject.__filters != null) {
			return true;
		}
		if(value == false || displayObject.__graphics != null && !(openfl_display__$internal_Context3DGraphics().default).isCompatible(displayObject.__graphics)) {
			return false;
		}
		return null;
	},
	__updateCacheBitmap: function(displayObject,force) {
		if(displayObject == null) {
			return false;
		}
		var renderer = this;
		switch(displayObject.__drawableType) {
		case 2:
			var bitmap = displayObject;
			if(bitmap.__bitmapData == null || bitmap.__filters == null && renderer.__type == "opengl" && bitmap.__cacheBitmap == null) {
				return false;
			}
			force = bitmap.__bitmapData.image != null && bitmap.__bitmapData.image.version != bitmap.__imageVersion;
			break;
		case 7:
			var textField = displayObject;
			if(textField.__filters == null && renderer.__type == "opengl" && textField.__cacheBitmap == null && !textField.__domRender) {
				return false;
			}
			if(force) {
				textField.__renderDirty = true;
			}
			force = force || textField.__dirty;
			break;
		case 9:
			var tilemap = displayObject;
			if(tilemap.__filters == null && renderer.__type == "opengl" && tilemap.__cacheBitmap == null) {
				return false;
			}
			break;
		default:
		}
		if(displayObject.__isCacheBitmapRender) {
			return false;
		}
		var colorTransform = (openfl_geom_ColorTransform().default).__pool.get();
		colorTransform.__copyFrom(displayObject.__worldColorTransform);
		if(renderer.__worldColorTransform != null) {
			colorTransform.__combine(renderer.__worldColorTransform);
		}
		var updated = false;
		if(displayObject.get_cacheAsBitmap() || renderer.__type != "opengl" && !colorTransform.__isDefault(true)) {
			var rect = null;
			var needRender = displayObject.__cacheBitmap == null || displayObject.__renderDirty && (force || displayObject.__children != null && displayObject.__children.length > 0) || displayObject.opaqueBackground != displayObject.__cacheBitmapBackground;
			var softwareDirty = needRender || displayObject.__graphics != null && displayObject.__graphics.__softwareDirty || !displayObject.__cacheBitmapColorTransform.__equals(colorTransform,true);
			var hardwareDirty = needRender || displayObject.__graphics != null && displayObject.__graphics.__hardwareDirty;
			var renderType = renderer.__type;
			if(softwareDirty || hardwareDirty) {
				if(renderType == "opengl") {
					if(this.__shouldCacheHardware(displayObject,null) == false) {
						renderType = "canvas";
					}
				}
				if(softwareDirty && (renderType == "canvas" || renderType == "cairo")) {
					needRender = true;
				}
				if(hardwareDirty && renderType == "opengl") {
					needRender = true;
				}
			}
			var updateTransform = needRender || !displayObject.__cacheBitmap.__worldTransform.equals(displayObject.__worldTransform);
			var hasFilters = displayObject.__filters != null;
			if(hasFilters && !needRender) {
				var _g1 = 0;
				var _g2 = displayObject.__filters;
				while(_g1 < _g2.length) {
					var filter = _g2[_g1];
					++_g1;
					if(filter.__renderDirty) {
						needRender = true;
						break;
					}
				}
			}
			if(displayObject.__cacheBitmapMatrix == null) {
				displayObject.__cacheBitmapMatrix = new (openfl_geom_Matrix().default)();
			}
			var bitmapMatrix = displayObject.__cacheAsBitmapMatrix != null ? displayObject.__cacheAsBitmapMatrix : displayObject.__renderTransform;
			if(!needRender && (bitmapMatrix.a != displayObject.__cacheBitmapMatrix.a || bitmapMatrix.b != displayObject.__cacheBitmapMatrix.b || bitmapMatrix.c != displayObject.__cacheBitmapMatrix.c || bitmapMatrix.d != displayObject.__cacheBitmapMatrix.d)) {
				needRender = true;
			}
			if(!needRender && renderer.__type != "opengl" && displayObject.__cacheBitmapData != null && displayObject.__cacheBitmapData.image != null && displayObject.__cacheBitmapData.image.version < displayObject.__cacheBitmapData.__textureVersion) {
				needRender = true;
			}
			displayObject.__cacheBitmapMatrix.copyFrom(bitmapMatrix);
			displayObject.__cacheBitmapMatrix.tx = 0;
			displayObject.__cacheBitmapMatrix.ty = 0;
			var bitmapWidth = 0;
			var bitmapHeight = 0;
			var filterWidth = 0;
			var filterHeight = 0;
			var offsetX = 0.;
			var offsetY = 0.;
			if(updateTransform || needRender) {
				rect = (openfl_geom_Rectangle().default).__pool.get();
				displayObject.__getFilterBounds(rect,displayObject.__cacheBitmapMatrix);
				filterWidth = Math.ceil(rect.width);
				filterHeight = Math.ceil(rect.height);
				offsetX = rect.x > 0 ? Math.ceil(rect.x) : Math.floor(rect.x);
				offsetY = rect.y > 0 ? Math.ceil(rect.y) : Math.floor(rect.y);
				if(displayObject.__cacheBitmapData != null) {
					if(filterWidth > displayObject.__cacheBitmapData.width || filterHeight > displayObject.__cacheBitmapData.height) {
						bitmapWidth = Math.ceil(Math.max(filterWidth * 1.25,displayObject.__cacheBitmapData.width));
						bitmapHeight = Math.ceil(Math.max(filterHeight * 1.25,displayObject.__cacheBitmapData.height));
						needRender = true;
					} else {
						bitmapWidth = displayObject.__cacheBitmapData.width;
						bitmapHeight = displayObject.__cacheBitmapData.height;
					}
				} else {
					bitmapWidth = filterWidth;
					bitmapHeight = filterHeight;
				}
			}
			if(needRender) {
				updateTransform = true;
				displayObject.__cacheBitmapBackground = displayObject.opaqueBackground;
				if(filterWidth >= 0.5 && filterHeight >= 0.5) {
					var needsFill = displayObject.opaqueBackground != null && (bitmapWidth != filterWidth || bitmapHeight != filterHeight);
					var fillColor = displayObject.opaqueBackground != null ? -16777216 | displayObject.opaqueBackground : 0;
					var bitmapColor = needsFill ? 0 : fillColor;
					var allowFramebuffer = renderer.__type == "opengl";
					if(displayObject.__cacheBitmapData == null || bitmapWidth > displayObject.__cacheBitmapData.width || bitmapHeight > displayObject.__cacheBitmapData.height) {
						displayObject.__cacheBitmapData = new (openfl_display_BitmapData().default)(bitmapWidth,bitmapHeight,true,bitmapColor);
						if(displayObject.__cacheBitmap == null) {
							displayObject.__cacheBitmap = new (openfl_display_Bitmap().default)();
						}
						displayObject.__cacheBitmap.__bitmapData = displayObject.__cacheBitmapData;
						displayObject.__cacheBitmapRenderer = null;
					} else {
						displayObject.__cacheBitmapData.__fillRect(displayObject.__cacheBitmapData.rect,bitmapColor,allowFramebuffer);
					}
					if(needsFill) {
						rect.setTo(0,0,filterWidth,filterHeight);
						displayObject.__cacheBitmapData.__fillRect(rect,fillColor,allowFramebuffer);
					}
				} else {
					(openfl_geom_ColorTransform().default).__pool.release(colorTransform);
					displayObject.__cacheBitmap = null;
					displayObject.__cacheBitmapData = null;
					displayObject.__cacheBitmapData2 = null;
					displayObject.__cacheBitmapData3 = null;
					displayObject.__cacheBitmapRenderer = null;
					if(displayObject.__drawableType == 7) {
						var textField1 = displayObject;
						if(textField1.__cacheBitmap != null) {
							textField1.__cacheBitmap.__renderTransform.tx -= textField1.__offsetX;
							textField1.__cacheBitmap.__renderTransform.ty -= textField1.__offsetY;
						}
					}
					return true;
				}
			} else {
				displayObject.__cacheBitmapData = displayObject.__cacheBitmap.get_bitmapData();
				displayObject.__cacheBitmapData2 = null;
				displayObject.__cacheBitmapData3 = null;
			}
			if(updateTransform || needRender) {
				displayObject.__cacheBitmap.__worldTransform.copyFrom(displayObject.__worldTransform);
				if(bitmapMatrix == displayObject.__renderTransform) {
					displayObject.__cacheBitmap.__renderTransform.identity();
					displayObject.__cacheBitmap.__renderTransform.tx = displayObject.__renderTransform.tx + offsetX;
					displayObject.__cacheBitmap.__renderTransform.ty = displayObject.__renderTransform.ty + offsetY;
				} else {
					displayObject.__cacheBitmap.__renderTransform.copyFrom(displayObject.__cacheBitmapMatrix);
					displayObject.__cacheBitmap.__renderTransform.invert();
					displayObject.__cacheBitmap.__renderTransform.concat(displayObject.__renderTransform);
					displayObject.__cacheBitmap.__renderTransform.tx += offsetX;
					displayObject.__cacheBitmap.__renderTransform.ty += offsetY;
				}
			}
			displayObject.__cacheBitmap.smoothing = renderer.__allowSmoothing;
			displayObject.__cacheBitmap.__renderable = displayObject.__renderable;
			displayObject.__cacheBitmap.__worldAlpha = displayObject.__worldAlpha;
			displayObject.__cacheBitmap.__worldBlendMode = displayObject.__worldBlendMode;
			displayObject.__cacheBitmap.__worldShader = displayObject.__worldShader;
			displayObject.__cacheBitmap.set_mask(displayObject.__mask);
			if(needRender) {
				if(displayObject.__cacheBitmapRenderer == null || renderType != displayObject.__cacheBitmapRenderer.__type) {
					if(renderType == "opengl") {
						displayObject.__cacheBitmapRenderer = new (openfl_display_OpenGLRenderer().default)(((js_Boot().default).__cast(renderer , (openfl_display_OpenGLRenderer().default))).__context3D,displayObject.__cacheBitmapData);
					} else {
						if(displayObject.__cacheBitmapData.image == null) {
							var color = displayObject.opaqueBackground != null ? -16777216 | displayObject.opaqueBackground : 0;
							displayObject.__cacheBitmapData = new (openfl_display_BitmapData().default)(bitmapWidth,bitmapHeight,true,color);
							displayObject.__cacheBitmap.__bitmapData = displayObject.__cacheBitmapData;
						}
						(lime__$internal_graphics_ImageCanvasUtil().default).convertToCanvas(displayObject.__cacheBitmapData.image);
						displayObject.__cacheBitmapRenderer = new (openfl_display_CanvasRenderer().default)(displayObject.__cacheBitmapData.image.buffer.__srcContext);
					}
					displayObject.__cacheBitmapRenderer.__worldTransform = new (openfl_geom_Matrix().default)();
					displayObject.__cacheBitmapRenderer.__worldColorTransform = new (openfl_geom_ColorTransform().default)();
				}
				if(displayObject.__cacheBitmapColorTransform == null) {
					displayObject.__cacheBitmapColorTransform = new (openfl_geom_ColorTransform().default)();
				}
				displayObject.__cacheBitmapRenderer.__stage = displayObject.stage;
				displayObject.__cacheBitmapRenderer.__allowSmoothing = renderer.__allowSmoothing;
				displayObject.__cacheBitmapRenderer.__setBlendMode("normal");
				displayObject.__cacheBitmapRenderer.__worldAlpha = 1 / displayObject.__worldAlpha;
				displayObject.__cacheBitmapRenderer.__worldTransform.copyFrom(displayObject.__renderTransform);
				displayObject.__cacheBitmapRenderer.__worldTransform.invert();
				displayObject.__cacheBitmapRenderer.__worldTransform.concat(displayObject.__cacheBitmapMatrix);
				displayObject.__cacheBitmapRenderer.__worldTransform.tx -= offsetX;
				displayObject.__cacheBitmapRenderer.__worldTransform.ty -= offsetY;
				displayObject.__cacheBitmapRenderer.__worldColorTransform.__copyFrom(colorTransform);
				displayObject.__cacheBitmapRenderer.__worldColorTransform.__invert();
				displayObject.__isCacheBitmapRender = true;
				if(displayObject.__cacheBitmapRenderer.__type == "opengl") {
					var parentRenderer = renderer;
					var childRenderer = displayObject.__cacheBitmapRenderer;
					var context = childRenderer.__context3D;
					var cacheRTT = context.__state.renderToTexture;
					var cacheRTTDepthStencil = context.__state.renderToTextureDepthStencil;
					var cacheRTTAntiAlias = context.__state.renderToTextureAntiAlias;
					var cacheRTTSurfaceSelector = context.__state.renderToTextureSurfaceSelector;
					var cacheBlendMode = parentRenderer.__blendMode;
					parentRenderer.__suspendClipAndMask();
					childRenderer.__copyShader(parentRenderer);
					displayObject.__cacheBitmapData.__setUVRect(context,0,0,filterWidth,filterHeight);
					childRenderer.__setRenderTarget(displayObject.__cacheBitmapData);
					if(displayObject.__cacheBitmapData.image != null) {
						displayObject.__cacheBitmapData.__textureVersion = displayObject.__cacheBitmapData.image.version + 1;
					}
					displayObject.__cacheBitmapData.__drawGL(displayObject,childRenderer);
					if(hasFilters) {
						var needSecondBitmapData = true;
						var needCopyOfOriginal = false;
						var _g11 = 0;
						var _g21 = displayObject.__filters;
						while(_g11 < _g21.length) {
							var filter1 = _g21[_g11];
							++_g11;
							if(filter1.__preserveObject) {
								needCopyOfOriginal = true;
							}
						}
						var bitmap1 = displayObject.__cacheBitmapData;
						var bitmap2 = null;
						var bitmap3 = null;
						if(displayObject.__cacheBitmapData2 == null || bitmapWidth > displayObject.__cacheBitmapData2.width || bitmapHeight > displayObject.__cacheBitmapData2.height) {
							displayObject.__cacheBitmapData2 = new (openfl_display_BitmapData().default)(bitmapWidth,bitmapHeight,true,0);
						} else {
							displayObject.__cacheBitmapData2.fillRect(displayObject.__cacheBitmapData2.rect,0);
							if(displayObject.__cacheBitmapData2.image != null) {
								displayObject.__cacheBitmapData2.__textureVersion = displayObject.__cacheBitmapData2.image.version + 1;
							}
						}
						displayObject.__cacheBitmapData2.__setUVRect(context,0,0,filterWidth,filterHeight);
						bitmap2 = displayObject.__cacheBitmapData2;
						if(needCopyOfOriginal) {
							if(displayObject.__cacheBitmapData3 == null || bitmapWidth > displayObject.__cacheBitmapData3.width || bitmapHeight > displayObject.__cacheBitmapData3.height) {
								displayObject.__cacheBitmapData3 = new (openfl_display_BitmapData().default)(bitmapWidth,bitmapHeight,true,0);
							} else {
								displayObject.__cacheBitmapData3.fillRect(displayObject.__cacheBitmapData3.rect,0);
								if(displayObject.__cacheBitmapData3.image != null) {
									displayObject.__cacheBitmapData3.__textureVersion = displayObject.__cacheBitmapData3.image.version + 1;
								}
							}
							displayObject.__cacheBitmapData3.__setUVRect(context,0,0,filterWidth,filterHeight);
							bitmap3 = displayObject.__cacheBitmapData3;
						}
						childRenderer.__setBlendMode("normal");
						childRenderer.__worldAlpha = 1;
						childRenderer.__worldTransform.identity();
						childRenderer.__worldColorTransform.__identity();
						var shader;
						var cacheBitmap;
						var _g3 = 0;
						var _g4 = displayObject.__filters;
						while(_g3 < _g4.length) {
							var filter2 = _g4[_g3];
							++_g3;
							if(filter2.__preserveObject) {
								childRenderer.__setRenderTarget(bitmap3);
								childRenderer.__renderFilterPass(bitmap1,childRenderer.__defaultDisplayShader,filter2.__smooth);
							}
							var _g31 = 0;
							var _g41 = filter2.__numShaderPasses;
							while(_g31 < _g41) {
								var i = _g31++;
								shader = filter2.__initShader(childRenderer,i,filter2.__preserveObject ? bitmap3 : null);
								childRenderer.__setBlendMode(filter2.__shaderBlendMode);
								childRenderer.__setRenderTarget(bitmap2);
								childRenderer.__renderFilterPass(bitmap1,shader,filter2.__smooth);
								cacheBitmap = bitmap1;
								bitmap1 = bitmap2;
								bitmap2 = cacheBitmap;
							}
							filter2.__renderDirty = false;
						}
						displayObject.__cacheBitmap.__bitmapData = bitmap1;
					}
					parentRenderer.__blendMode = "normal";
					parentRenderer.__setBlendMode(cacheBlendMode);
					parentRenderer.__copyShader(childRenderer);
					if(cacheRTT != null) {
						context.setRenderToTexture(cacheRTT,cacheRTTDepthStencil,cacheRTTAntiAlias,cacheRTTSurfaceSelector);
					} else {
						context.setRenderToBackBuffer();
					}
					parentRenderer.__resumeClipAndMask(childRenderer);
					parentRenderer.setViewport();
					displayObject.__cacheBitmapColorTransform.__copyFrom(colorTransform);
				} else {
					displayObject.__cacheBitmapData.__drawCanvas(displayObject,displayObject.__cacheBitmapRenderer);
					if(hasFilters) {
						var needSecondBitmapData1 = false;
						var needCopyOfOriginal1 = false;
						var _g12 = 0;
						var _g22 = displayObject.__filters;
						while(_g12 < _g22.length) {
							var filter3 = _g22[_g12];
							++_g12;
							if(filter3.__needSecondBitmapData) {
								needSecondBitmapData1 = true;
							}
							if(filter3.__preserveObject) {
								needCopyOfOriginal1 = true;
							}
						}
						var bitmap4 = displayObject.__cacheBitmapData;
						var bitmap21 = null;
						var bitmap31 = null;
						if(needSecondBitmapData1) {
							if(displayObject.__cacheBitmapData2 == null || displayObject.__cacheBitmapData2.image == null || bitmapWidth > displayObject.__cacheBitmapData2.width || bitmapHeight > displayObject.__cacheBitmapData2.height) {
								displayObject.__cacheBitmapData2 = new (openfl_display_BitmapData().default)(bitmapWidth,bitmapHeight,true,0);
							} else {
								displayObject.__cacheBitmapData2.fillRect(displayObject.__cacheBitmapData2.rect,0);
							}
							bitmap21 = displayObject.__cacheBitmapData2;
						} else {
							bitmap21 = bitmap4;
						}
						if(needCopyOfOriginal1) {
							if(displayObject.__cacheBitmapData3 == null || displayObject.__cacheBitmapData3.image == null || bitmapWidth > displayObject.__cacheBitmapData3.width || bitmapHeight > displayObject.__cacheBitmapData3.height) {
								displayObject.__cacheBitmapData3 = new (openfl_display_BitmapData().default)(bitmapWidth,bitmapHeight,true,0);
							} else {
								displayObject.__cacheBitmapData3.fillRect(displayObject.__cacheBitmapData3.rect,0);
							}
							bitmap31 = displayObject.__cacheBitmapData3;
						}
						if(displayObject.__tempPoint == null) {
							displayObject.__tempPoint = new (openfl_geom_Point().default)();
						}
						var destPoint = displayObject.__tempPoint;
						var cacheBitmap1;
						var lastBitmap;
						var _g32 = 0;
						var _g42 = displayObject.__filters;
						while(_g32 < _g42.length) {
							var filter4 = _g42[_g32];
							++_g32;
							if(filter4.__preserveObject) {
								bitmap31.copyPixels(bitmap4,bitmap4.rect,destPoint);
							}
							lastBitmap = filter4.__applyFilter(bitmap21,bitmap4,bitmap4.rect,destPoint);
							if(filter4.__preserveObject) {
								lastBitmap.draw(bitmap31,null,displayObject.__objectTransform != null ? displayObject.__objectTransform.get_colorTransform() : null);
							}
							filter4.__renderDirty = false;
							if(needSecondBitmapData1 && lastBitmap == bitmap21) {
								cacheBitmap1 = bitmap4;
								bitmap4 = bitmap21;
								bitmap21 = cacheBitmap1;
							}
						}
						if(displayObject.__cacheBitmapData != bitmap4) {
							cacheBitmap1 = displayObject.__cacheBitmapData;
							displayObject.__cacheBitmapData = bitmap4;
							displayObject.__cacheBitmapData2 = cacheBitmap1;
							displayObject.__cacheBitmap.__bitmapData = displayObject.__cacheBitmapData;
							displayObject.__cacheBitmapRenderer = null;
						}
						displayObject.__cacheBitmap.__imageVersion = displayObject.__cacheBitmapData.__textureVersion;
					}
					displayObject.__cacheBitmapColorTransform.__copyFrom(colorTransform);
					if(!displayObject.__cacheBitmapColorTransform.__isDefault(true)) {
						displayObject.__cacheBitmapColorTransform.alphaMultiplier = 1;
						displayObject.__cacheBitmapData.colorTransform(displayObject.__cacheBitmapData.rect,displayObject.__cacheBitmapColorTransform);
					}
				}
				displayObject.__isCacheBitmapRender = false;
			}
			if(updateTransform || needRender) {
				(openfl_geom_Rectangle().default).__pool.release(rect);
			}
			updated = updateTransform;
		} else if(displayObject.__cacheBitmap != null) {
			if(renderer.__type == "dom") {
				var domRenderer = renderer;
				domRenderer.__renderDrawableClear(displayObject.__cacheBitmap);
			}
			displayObject.__cacheBitmap = null;
			displayObject.__cacheBitmapData = null;
			displayObject.__cacheBitmapData2 = null;
			displayObject.__cacheBitmapData3 = null;
			displayObject.__cacheBitmapColorTransform = null;
			displayObject.__cacheBitmapRenderer = null;
			updated = true;
		}
		(openfl_geom_ColorTransform().default).__pool.release(colorTransform);
		if(updated && displayObject.__drawableType == 7) {
			var textField2 = displayObject;
			if(textField2.__cacheBitmap != null) {
				textField2.__cacheBitmap.__renderTransform.tx -= textField2.__offsetX;
				textField2.__cacheBitmap.__renderTransform.ty -= textField2.__offsetY;
			}
		}
		return updated;
	}
});
DisplayObjectRenderer.prototype.__class__ = DisplayObjectRenderer.prototype.constructor = $hxClasses["openfl.display.DisplayObjectRenderer"] = DisplayObjectRenderer;

// Init



// Statics


DisplayObjectRenderer.__meta__ = { fields : { __context : { SuppressWarnings : ["checkstyle:Dynamic"]}, __type : { SuppressWarnings : ["checkstyle:Dynamic"]}}}

// Export

exports.default = DisplayObjectRenderer;