// Class: openfl.display._internal.DOMSimpleButton

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
function openfl__$Vector_VectorIterator() {return require("./../../../openfl/_Vector/VectorIterator");}
function openfl_display__$internal_DOMDisplayObject() {return require("./../../../openfl/display/_internal/DOMDisplayObject");}

// Constructor

var DOMSimpleButton = function(){}

// Meta

DOMSimpleButton.__name__ = "openfl.display._internal.DOMSimpleButton";
DOMSimpleButton.__isInterface__ = false;
DOMSimpleButton.prototype = {
	
};
DOMSimpleButton.prototype.__class__ = DOMSimpleButton.prototype.constructor = $hxClasses["openfl.display._internal.DOMSimpleButton"] = DOMSimpleButton;

// Init



// Statics

DOMSimpleButton.renderDrawable = function(simpleButton,renderer) {
	renderer.__pushMaskObject(simpleButton);
	var previousState = new (openfl__$Vector_VectorIterator().default)(simpleButton.__previousStates);
	while(previousState.hasNext()) {
		var previousState1 = previousState.next();
		renderer.__renderDrawable(previousState1);
	}
	simpleButton.__previousStates.set_length(0);
	if(simpleButton.__currentState != null) {
		if(simpleButton.__currentState.stage != simpleButton.stage) {
			simpleButton.__currentState.__setStageReference(simpleButton.stage);
		}
		renderer.__renderDrawable(simpleButton.__currentState);
	}
	renderer.__popMaskObject(simpleButton);
	renderer.__renderEvent(simpleButton);
}
DOMSimpleButton.renderDrawableClear = function(simpleButton,renderer) {
	(openfl_display__$internal_DOMDisplayObject().default).renderDrawableClear(simpleButton,renderer);
}


// Export

exports.default = DOMSimpleButton;