Object.defineProperty (module.exports, "__esModule", { value: true });
module.exports.ShaderParameterType = module.exports.default = {
	BOOL: "bool",
	BOOL2: "bool2",
	BOOL3: "bool3",
	BOOL4: "bool4",
	FLOAT: "float",
	FLOAT2: "float2",
	FLOAT3: "float3",
	FLOAT4: "float4",
	INT: "int",
	INT2: "int2",
	INT3: "int3",
	INT4: "int4",
	MATRIX2X2: "matrix2x2",
	MATRIX2X3: "matrix2x3",
	MATRIX2X4: "matrix2x4",
	MATRIX3X2: "matrix3x2",
	MATRIX3X3: "matrix3x3",
	MATRIX3X4: "matrix3x4",
	MATRIX4X2: "matrix4x2",
	MATRIX4X3: "matrix4x3",
	MATRIX4X4: "matrix4x4",
};