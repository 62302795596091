// Class: openfl.display.MovieClip

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../hxClasses_stub").default;
var $hxEnums = require("./../../hxEnums_stub").default;
var $import = require("./../../import_stub").default;
var $bind = require("./../../bind_stub").default;
var $extend = require("./../../extend_stub").default;
function openfl_display_Sprite() {return require("./../../openfl/display/Sprite");}
function openfl_display_Scene() {return require("./../../openfl/display/Scene");}

// Constructor

var MovieClip = function() {
	(openfl_display_Sprite().default).call(this);
	this.__enabled = true;
	if(MovieClip.__constructor != null) {
		var method = MovieClip.__constructor;
		MovieClip.__constructor = null;
		method(this);
	}
}

// Meta

MovieClip.__name__ = "openfl.display.MovieClip";
MovieClip.__isInterface__ = false;
MovieClip.__super__ = (openfl_display_Sprite().default);
MovieClip.prototype = $extend((openfl_display_Sprite().default).prototype, {
	addFrameScript: function(index,method) {
		if(this.__timeline != null) {
			this.__timeline.__addFrameScript(index,method);
		}
	},
	attachTimeline: function(timeline) {
		this.__timeline = timeline;
		if(timeline != null) {
			timeline.__attachMovieClip(this);
			this.play();
		}
	},
	gotoAndPlay: function(frame,scene) {
		if(this.__timeline != null) {
			this.__timeline.__gotoAndPlay(frame,scene);
		}
	},
	gotoAndStop: function(frame,scene) {
		if(this.__timeline != null) {
			this.__timeline.__gotoAndStop(frame,scene);
		}
	},
	nextFrame: function() {
		if(this.__timeline != null) {
			this.__timeline.__nextFrame();
		}
	},
	nextScene: function() {
		if(this.__timeline != null) {
			this.__timeline.__nextScene();
		}
	},
	play: function() {
		if(this.__timeline != null) {
			this.__timeline.__play();
		}
	},
	prevFrame: function() {
		if(this.__timeline != null) {
			this.__timeline.__prevFrame();
		}
	},
	prevScene: function() {
		if(this.__timeline != null) {
			this.__timeline.__prevScene();
		}
	},
	stop: function() {
		if(this.__timeline != null) {
			this.__timeline.__stop();
		}
	},
	__enterFrame: function(deltaTime) {
		if(this.__timeline != null) {
			this.__timeline.__enterFrame(deltaTime);
		}
		var _g = 0;
		var _g1 = this.__children;
		while(_g < _g1.length) {
			var child = _g1[_g];
			++_g;
			child.__enterFrame(deltaTime);
		}
	},
	__stopAllMovieClips: function() {
		(openfl_display_Sprite().default).prototype.__stopAllMovieClips.call(this);
		this.stop();
	},
	__tabTest: function(stack) {
		if(!this.__enabled) {
			return;
		}
		(openfl_display_Sprite().default).prototype.__tabTest.call(this,stack);
	},
	__onMouseDown: function(event) {
		if(this.__enabled && this.__hasDown) {
			this.gotoAndStop("_down");
		}
		this.__mouseIsDown = true;
		if(this.stage != null) {
			this.stage.addEventListener("mouseUp",$bind(this,this.__onMouseUp),true);
		}
	},
	__onMouseUp: function(event) {
		this.__mouseIsDown = false;
		if(this.stage != null) {
			this.stage.removeEventListener("mouseUp",$bind(this,this.__onMouseUp));
		}
		if(!this.__buttonMode) {
			return;
		}
		if(event.target == this && this.__enabled && this.__hasOver) {
			this.gotoAndStop("_over");
		} else if(this.__enabled && this.__hasUp) {
			this.gotoAndStop("_up");
		}
	},
	__onRollOut: function(event) {
		if(!this.__enabled) {
			return;
		}
		if(this.__mouseIsDown && this.__hasOver) {
			this.gotoAndStop("_over");
		} else if(this.__hasUp) {
			this.gotoAndStop("_up");
		}
	},
	__onRollOver: function(event) {
		if(this.__enabled && this.__hasOver) {
			this.gotoAndStop("_over");
		}
	},
	set_buttonMode: function(value) {
		if(this.__buttonMode != value) {
			if(value) {
				this.__hasDown = false;
				this.__hasOver = false;
				this.__hasUp = false;
				var _g = 0;
				var _g1 = this.get_currentLabels();
				while(_g < _g1.length) {
					var frameLabel = _g1[_g];
					++_g;
					switch(frameLabel.name) {
					case "_down":
						this.__hasDown = true;
						break;
					case "_over":
						this.__hasOver = true;
						break;
					case "_up":
						this.__hasUp = true;
						break;
					default:
					}
				}
				if(this.__hasDown || this.__hasOver || this.__hasUp) {
					this.addEventListener("rollOver",$bind(this,this.__onRollOver));
					this.addEventListener("rollOut",$bind(this,this.__onRollOut));
					this.addEventListener("mouseDown",$bind(this,this.__onMouseDown));
				}
			} else {
				this.removeEventListener("rollOver",$bind(this,this.__onRollOver));
				this.removeEventListener("rollOut",$bind(this,this.__onRollOut));
				this.removeEventListener("mouseDown",$bind(this,this.__onMouseDown));
			}
			this.__buttonMode = value;
		}
		return value;
	},
	get_currentFrame: function() {
		if(this.__timeline != null) {
			return this.__timeline.__currentFrame;
		} else {
			return 1;
		}
	},
	get_currentFrameLabel: function() {
		if(this.__timeline != null) {
			return this.__timeline.__currentFrameLabel;
		} else {
			return null;
		}
	},
	get_currentLabel: function() {
		if(this.__timeline != null) {
			return this.__timeline.__currentLabel;
		} else {
			return null;
		}
	},
	get_currentLabels: function() {
		if(this.__timeline != null) {
			return this.__timeline.__currentLabels.slice();
		} else {
			return [];
		}
	},
	get_currentScene: function() {
		if(this.__timeline != null) {
			return this.__timeline.__currentScene;
		} else {
			if(this.__scene == null) {
				this.__scene = new (openfl_display_Scene().default)("",[],1);
			}
			return this.__scene;
		}
	},
	get_enabled: function() {
		return this.__enabled;
	},
	set_enabled: function(value) {
		return this.__enabled = value;
	},
	get_framesLoaded: function() {
		if(this.__timeline != null) {
			return this.__timeline.__framesLoaded;
		} else {
			return 1;
		}
	},
	get_isPlaying: function() {
		if(this.__timeline != null) {
			return this.__timeline.__isPlaying;
		} else {
			return false;
		}
	},
	get_scenes: function() {
		if(this.__timeline != null) {
			return this.__timeline.scenes.slice();
		} else {
			return [this.get_currentScene()];
		}
	},
	get_totalFrames: function() {
		if(this.__timeline != null) {
			return this.__timeline.__totalFrames;
		} else {
			return 1;
		}
	}
});
MovieClip.prototype.__class__ = MovieClip.prototype.constructor = $hxClasses["openfl.display.MovieClip"] = MovieClip;

// Init

Object.defineProperties(MovieClip.prototype,{ currentFrame : { get : function () { return this.get_currentFrame (); }}, currentFrameLabel : { get : function () { return this.get_currentFrameLabel (); }}, currentLabel : { get : function () { return this.get_currentLabel (); }}, currentLabels : { get : function () { return this.get_currentLabels (); }}, enabled : { get : function () { return this.get_enabled (); }, set : function (v) { return this.set_enabled (v); }}, framesLoaded : { get : function () { return this.get_framesLoaded (); }}, isPlaying : { get : function () { return this.get_isPlaying (); }}, totalFrames : { get : function () { return this.get_totalFrames (); }}});

// Statics

MovieClip.fromTimeline = function(timeline) {
	var movieClip = new MovieClip();
	movieClip.attachTimeline(timeline);
	return movieClip;
}


// Export

exports.default = MovieClip;