module.exports = {

	desktop: require("./desktop"),

	Clipboard: require("./desktop/Clipboard").default,
	ClipboardFormats: require("./desktop/ClipboardFormats").default,
	ClipboardTransferMode: require("./desktop/ClipboardTransferMode").default,


	display: require("./display"),

	// Application: require("./display/Application").default,
	Bitmap: require("./display/Bitmap").default,
	BitmapData: require("./display/BitmapData").default,
	BitmapDataChannel: require("./display/BitmapDataChannel").default,
	BlendMode: require("./display/BlendMode").default,
	CanvasRenderer: require("./display/CanvasRenderer").default,
	CapsStyle: require("./display/CapsStyle").default,
	DisplayObject: require("./display/DisplayObject").default,
	DisplayObjectContainer: require("./display/DisplayObjectContainer").default,
	DisplayObjectRenderer: require("./display/DisplayObjectRenderer").default,
	DisplayObjectShader: require("./display/DisplayObjectShader").default,
	DOMElement: require("./display/DOMElement").default,
	DOMRenderer: require("./display/DOMRenderer").default,
	FPS: require("./display/FPS").default,
	FrameLabel: require("./display/FrameLabel").default,
	GradientType: require("./display/GradientType").default,
	Graphics: require("./display/Graphics").default,
	GraphicsBitmapFill: require("./display/GraphicsBitmapFill").default,
	GraphicsEndFill: require("./display/GraphicsEndFill").default,
	GraphicsGradientFill: require("./display/GraphicsGradientFill").default,
	GraphicsPath: require("./display/GraphicsPath").default,
	GraphicsPathCommand: require("./display/GraphicsPathCommand").default,
	GraphicsPathWinding: require("./display/GraphicsPathWinding").default,
	GraphicsQuadPath: require("./display/GraphicsQuadPath").default,
	GraphicsShader: require("./display/GraphicsShader").default,
	GraphicsShaderFill: require("./display/GraphicsShaderFill").default,
	GraphicsSolidFill: require("./display/GraphicsSolidFill").default,
	GraphicsStroke: require("./display/GraphicsStroke").default,
	GraphicsTrianglePath: require("./display/GraphicsTrianglePath").default,
	IBitmapDrawable: require("./display/IBitmapDrawable").default,
	IGraphicsData: require("./display/IGraphicsData").default,
	IGraphicsFill: require("./display/IGraphicsFill").default,
	IGraphicsPath: require("./display/IGraphicsPath").default,
	IGraphicsStroke: require("./display/IGraphicsStroke").default,
	InteractiveObject: require("./display/InteractiveObject").default,
	InterpolationMethod: require("./display/InterpolationMethod").default,
	ITileContainer: require("./display/ITileContainer").default,
	JointStyle: require("./display/JointStyle").default,
	JPEGEncoderOptions: require("./display/JPEGEncoderOptions").default,
	LineScaleMode: require("./display/LineScaleMode").default,
	Loader: require("./display/Loader").default,
	LoaderInfo: require("./display/LoaderInfo").default,
	MovieClip: require("./display/MovieClip").default,
	OpenGLRenderer: require("./display/OpenGLRenderer").default,
	PixelSnapping: require("./display/PixelSnapping").default,
	PNGEncoderOptions: require("./display/PNGEncoderOptions").default,
	Preloader: require("./display/Preloader").default,
	Shader: require("./display/Shader").default,
	ShaderData: require("./display/ShaderData").default,
	ShaderInput: require("./display/ShaderInput").default,
	ShaderJob: require("./display/ShaderJob").default,
	ShaderParameter: require("./display/ShaderParameter").default,
	ShaderParameterType: require("./display/ShaderParameterType").default,
	ShaderPrecision: require("./display/ShaderPrecision").default,
	Shape: require("./display/Shape").default,
	SimpleButton: require("./display/SimpleButton").default,
	SpreadMethod: require("./display/SpreadMethod").default,
	Sprite: require("./display/Sprite").default,
	Stage: require("./display/Stage").default,
	Stage3D: require("./display/Stage3D").default,
	StageAlign: require("./display/StageAlign").default,
	StageDisplayState: require("./display/StageDisplayState").default,
	StageQuality: require("./display/StageQuality").default,
	StageScaleMode: require("./display/StageScaleMode").default,
	Tile: require("./display/Tile").default,
	TileContainer: require("./display/TileContainer").default,
	Tilemap: require("./display/Tilemap").default,
	Tileset: require("./display/Tileset").default,
	TriangleCulling: require("./display/TriangleCulling").default,
	// Window: require("./display/Window").default,


	display3D: require("./display3D"),

	CubeTexture: require("./display3D/textures/CubeTexture").default,
	RectangleTexture: require("./display3D/textures/RectangleTexture").default,
	Texture: require("./display3D/textures/Texture").default,
	TextureBase: require("./display3D/textures/TextureBase").default,
	VideoTexture: require("./display3D/textures/VideoTexture").default,

	Context3D: require("./display3D/Context3D").default,
	Context3DBlendFactor: require("./display3D/Context3DBlendFactor").default,
	Context3DBufferUsage: require("./display3D/Context3DBufferUsage").default,
	Context3DClearMask: require("./display3D/Context3DClearMask").default,
	Context3DCompareMode: require("./display3D/Context3DCompareMode").default,
	Context3DMipFilter: require("./display3D/Context3DMipFilter").default,
	Context3DProfile: require("./display3D/Context3DProfile").default,
	Context3DProgramType: require("./display3D/Context3DProgramType").default,
	Context3DRenderMode: require("./display3D/Context3DRenderMode").default,
	Context3DStencilAction: require("./display3D/Context3DStencilAction").default,
	Context3DTextureFilter: require("./display3D/Context3DTextureFilter").default,
	Context3DTextureFormat: require("./display3D/Context3DTextureFormat").default,
	Context3DTriangleFace: require("./display3D/Context3DTriangleFace").default,
	Context3DVertexBufferFormat: require("./display3D/Context3DVertexBufferFormat").default,
	Context3DWrapMode: require("./display3D/Context3DWrapMode").default,
	IndexBuffer3D: require("./display3D/IndexBuffer3D").default,
	Program3D: require("./display3D/Program3D").default,
	VertexBuffer3D: require("./display3D/VertexBuffer3D").default,


	errors: require("./errors"),

	ArgumentError: require("./errors/ArgumentError").default,
	EOFError: require("./errors/EOFError").default,
	Error: require("./errors/Error").default,
	IllegalOperationError: require("./errors/IllegalOperationError").default,
	IOError: require("./errors/IOError").default,
	RangeError: require("./errors/RangeError").default,
	SecurityError: require("./errors/SecurityError").default,
	TypeError: require("./errors/TypeError").default,


	events: require("./events"),

	AccelerometerEvent: require("./events/AccelerometerEvent").default,
	ActivityEvent: require("./events/ActivityEvent").default,
	AsyncErrorEvent: require("./events/AsyncErrorEvent").default,
	DataEvent: require("./events/DataEvent").default,
	ErrorEvent: require("./events/ErrorEvent").default,
	Event: require("./events/Event").default,
	EventDispatcher: require("./events/EventDispatcher").default,
	EventPhase: require("./events/EventPhase").default,
	FocusEvent: require("./events/FocusEvent").default,
	FullScreenEvent: require("./events/FullScreenEvent").default,
	GameInputEvent: require("./events/GameInputEvent").default,
	HTTPStatusEvent: require("./events/HTTPStatusEvent").default,
	IEventDispatcher: require("./events/IEventDispatcher").default,
	IOErrorEvent: require("./events/IOErrorEvent").default,
	KeyboardEvent: require("./events/KeyboardEvent").default,
	MouseEvent: require("./events/MouseEvent").default,
	NetStatusEvent: require("./events/NetStatusEvent").default,
	ProgressEvent: require("./events/ProgressEvent").default,
	RenderEvent: require("./events/RenderEvent").default,
	SampleDataEvent: require("./events/SampleDataEvent").default,
	SecurityErrorEvent: require("./events/SecurityErrorEvent").default,
	TextEvent: require("./events/TextEvent").default,
	TimerEvent: require("./events/TimerEvent").default,
	TouchEvent: require("./events/TouchEvent").default,
	UncaughtErrorEvent: require("./events/UncaughtErrorEvent").default,
	UncaughtErrorEvents: require("./events/UncaughtErrorEvents").default,
	VideoTextureEvent: require("./events/VideoTextureEvent").default,


	external: require("./external"),

	ExternalInterface: require("./external/ExternalInterface").default,


	filters: require("./filters"),

	BitmapFilter: require("./filters/BitmapFilter").default,
	BitmapFilterQuality: require("./filters/BitmapFilterQuality").default,
	BitmapFilterShader: require("./filters/BitmapFilterShader").default,
	BitmapFilterType: require("./filters/BitmapFilterType").default,
	BlurFilter: require("./filters/BlurFilter").default,
	ColorMatrixFilter: require("./filters/ColorMatrixFilter").default,
	ConvolutionFilter: require("./filters/ConvolutionFilter").default,
	DropShadowFilter: require("./filters/DropShadowFilter").default,
	GlowFilter: require("./filters/GlowFilter").default,
	ShaderFilter: require("./filters/ShaderFilter").default,


	geom: require("./geom"),

	ColorTransform: require("./geom/ColorTransform").default,
	Matrix: require("./geom/Matrix").default,
	Matrix3D: require("./geom/Matrix3D").default,
	Orientation3D: require("./geom/Orientation3D").default,
	PerspectiveProjection: require("./geom/PerspectiveProjection").default,
	Point: require("./geom/Point").default,
	Rectangle: require("./geom/Rectangle").default,
	Transform: require("./geom/Transform").default,
	Utils3D: require("./geom/Utils3D").default,
	Vector3D: require("./geom/Vector3D").default,


	media: require("./media"),

	ID3Info: require("./media/ID3Info").default,
	Sound: require("./media/Sound").default,
	SoundChannel: require("./media/SoundChannel").default,
	SoundLoaderContext: require("./media/SoundLoaderContext").default,
	SoundMixer: require("./media/SoundMixer").default,
	SoundTransform: require("./media/SoundTransform").default,
	Video: require("./media/Video").default,


	net: require("./net"),

	FileFilter: require("./net/FileFilter").default,
	FileReference: require("./net/FileReference").default,
	FileReferenceList: require("./net/FileReferenceList").default,
	NetConnection: require("./net/NetConnection").default,
	NetStream: require("./net/NetStream").default,
	ObjectEncoding: require("./net/ObjectEncoding").default,
	SharedObject: require("./net/SharedObject").default,
	SharedObjectFlushStatus: require("./net/SharedObjectFlushStatus").default,
	Socket: require("./net/Socket").default,
	URLLoader: require("./net/URLLoader").default,
	URLLoaderDataFormat: require("./net/URLLoaderDataFormat").default,
	URLRequest: require("./net/URLRequest").default,
	URLRequestDefaults: require("./net/URLRequestDefaults").default,
	URLRequestHeader: require("./net/URLRequestHeader").default,
	URLRequestMethod: require("./net/URLRequestMethod").default,
	URLStream: require("./net/URLStream").default,
	URLVariables: require("./net/URLVariables").default,
	XMLSocket: require("./net/XMLSocket").default,
	navigateToURL: require("./net/navigateToURL").default,
	sendToURL: require("./net/sendToURL").default,


	printing: require("./printing"),

	PrintJob: require("./printing/PrintJob").default,
	PrintJobOptions: require("./printing/PrintJobOptions").default,
	PrintJobOrientation: require("./printing/PrintJobOrientation").default,


	profiler: require("./profiler"),

	Telemetry: require("./profiler/Telemetry").default,


	sensors: require("./sensors"),

	Accelerometer: require("./sensors/Accelerometer").default,


	system: require("./system"),

	ApplicationDomain: require("./system/ApplicationDomain").default,
	Capabilities: require("./system/Capabilities").default,
	LoaderContext: require("./system/LoaderContext").default,
	Security: require("./system/Security").default,
	SecurityDomain: require("./system/SecurityDomain").default,
	System: require("./system/System").default,
	TouchscreenType: require("./system/TouchscreenType").default,


	text: require("./text"),

	AntiAliasType: require("./text/AntiAliasType").default,
	Font: require("./text/Font").default,
	FontStyle: require("./text/FontStyle").default,
	FontType: require("./text/FontType").default,
	GridFitType: require("./text/GridFitType").default,
	TextField: require("./text/TextField").default,
	TextFieldAutoSize: require("./text/TextFieldAutoSize").default,
	TextFieldType: require("./text/TextFieldType").default,
	TextFormat: require("./text/TextFormat").default,
	TextFormatAlign: require("./text/TextFormatAlign").default,
	TextLineMetrics: require("./text/TextLineMetrics").default,


	ui: require("./ui"),

	GameInput: require("./ui/GameInput").default,
	GameInputControl: require("./ui/GameInputControl").default,
	GameInputDevice: require("./ui/GameInputDevice").default,
	Keyboard: require("./ui/Keyboard").default,
	KeyLocation: require("./ui/KeyLocation").default,
	Mouse: require("./ui/Mouse").default,
	MouseCursor: require("./ui/MouseCursor").default,
	Multitouch: require("./ui/Multitouch").default,
	MultitouchInputMode: require("./ui/MultitouchInputMode").default,


	utils: require("./utils"),

	AGALMiniAssembler: require("./utils/AGALMiniAssembler").default,
	AssetCache: require("./utils/AssetCache").default,
	AssetLibrary: require("./utils/AssetLibrary").default,
	AssetManifest: require("./utils/AssetManifest").default,
	Assets: require("./utils/Assets").default,
	AssetType: require("./utils/AssetType").default,
	ByteArray: require("./utils/ByteArray").default,
	CompressionAlgorithm: require("./utils/CompressionAlgorithm").default,
	Dictionary: require("./utils/Dictionary").default,
	Endian: require("./utils/Endian").default,
	Future: require("./utils/Future").default,
	IAssetCache: require("./utils/IAssetCache").default,
	IDataInput: require("./utils/IDataInput").default,
	IDataOutput: require("./utils/IDataOutput").default,
	// IExternalizable: require("./utils/IExternalizable").default,
	// Object: require("./utils/Object").default,
	Timer: require("./utils/Timer").default,
	clearTimeout: require("./utils/clearTimeout").default,
	getDefinitionByName: require("./utils/getDefinitionByName").default,
	getQualifiedClassName: require("./utils/getQualifiedClassName").default,
	getQualifiedSuperclassName: require("./utils/getQualifiedSuperclassName").default,
	getTimer: require("./utils/getTimer").default,
	setTimeout: require("./utils/setTimeout").default,


	Lib: require("./Lib").default,
	Memory: require("./Memory").default,
	Vector: require("./Vector").default,

}
