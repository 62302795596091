Object.defineProperty (module.exports, "__esModule", { value: true });
module.exports.AssetType = module.exports.default = {
	BINARY: "BINARY",
	FONT: "FONT",
	IMAGE: "IMAGE",
	MOVIE_CLIP: "MOVIE_CLIP",
	MUSIC: "MUSIC",
	SOUND: "SOUND",
	TEMPLATE: "TEMPLATE",
	TEXT: "TEXT"
};