// Class: openfl.display._internal.Context3DDisplayObjectContainer

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
function openfl_display__$internal_Context3DDisplayObject() {return require("./../../../openfl/display/_internal/Context3DDisplayObject");}
function openfl_display__$internal_Context3DShape() {return require("./../../../openfl/display/_internal/Context3DShape");}

// Constructor

var Context3DDisplayObjectContainer = function(){}

// Meta

Context3DDisplayObjectContainer.__name__ = "openfl.display._internal.Context3DDisplayObjectContainer";
Context3DDisplayObjectContainer.__isInterface__ = false;
Context3DDisplayObjectContainer.prototype = {
	
};
Context3DDisplayObjectContainer.prototype.__class__ = Context3DDisplayObjectContainer.prototype.constructor = $hxClasses["openfl.display._internal.Context3DDisplayObjectContainer"] = Context3DDisplayObjectContainer;

// Init



// Statics

Context3DDisplayObjectContainer.renderDrawable = function(displayObjectContainer,renderer) {
	displayObjectContainer.__cleanupRemovedChildren();
	if(!displayObjectContainer.__renderable || displayObjectContainer.__worldAlpha <= 0) {
		return;
	}
	(openfl_display__$internal_Context3DDisplayObject().default).renderDrawable(displayObjectContainer,renderer);
	if(displayObjectContainer.__cacheBitmap != null && !displayObjectContainer.__isCacheBitmapRender) {
		return;
	}
	if(displayObjectContainer.__children.length > 0) {
		renderer.__pushMaskObject(displayObjectContainer);
		if(renderer.__stage != null) {
			var _g = 0;
			var _g1 = displayObjectContainer.__children;
			while(_g < _g1.length) {
				var child = _g1[_g];
				++_g;
				renderer.__renderDrawable(child);
				child.__renderDirty = false;
			}
			displayObjectContainer.__renderDirty = false;
		} else {
			var _g2 = 0;
			var _g11 = displayObjectContainer.__children;
			while(_g2 < _g11.length) {
				var child1 = _g11[_g2];
				++_g2;
				renderer.__renderDrawable(child1);
			}
		}
	}
	if(displayObjectContainer.__children.length > 0) {
		renderer.__popMaskObject(displayObjectContainer);
	}
}
Context3DDisplayObjectContainer.renderDrawableMask = function(displayObjectContainer,renderer) {
	displayObjectContainer.__cleanupRemovedChildren();
	if(displayObjectContainer.__graphics != null) {
		(openfl_display__$internal_Context3DShape().default).renderMask(displayObjectContainer,renderer);
	}
	var _g = 0;
	var _g1 = displayObjectContainer.__children;
	while(_g < _g1.length) {
		var child = _g1[_g];
		++_g;
		renderer.__renderDrawableMask(child);
	}
}


// Export

exports.default = Context3DDisplayObjectContainer;