// Class: swf.exporters.swflite.SpriteSymbol

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
var $bind = require("./../../../bind_stub").default;
var $extend = require("./../../../extend_stub").default;
function swf_exporters_swflite_SWFSymbol() {return require("./../../../swf/exporters/swflite/SWFSymbol");}
function swf_exporters_swflite_timeline_SymbolTimeline() {return require("./../../../swf/exporters/swflite/timeline/SymbolTimeline");}
function openfl_display_MovieClip() {return require("./../../../openfl/display/MovieClip");}
function Type() {return require("./../../../Type");}

// Constructor

var SpriteSymbol = function() {
	(swf_exporters_swflite_SWFSymbol().default).call(this);
	this.frames = [];
}

// Meta

SpriteSymbol.__name__ = "swf.exporters.swflite.SpriteSymbol";
SpriteSymbol.__isInterface__ = false;
SpriteSymbol.__super__ = (swf_exporters_swflite_SWFSymbol().default);
SpriteSymbol.prototype = $extend((swf_exporters_swflite_SWFSymbol().default).prototype, {
	__constructor: function(movieClip) {
		var timeline = new (swf_exporters_swflite_timeline_SymbolTimeline().default)(this.swf,this);
		movieClip.attachTimeline(timeline);
		movieClip.set_scale9Grid(this.scale9Grid);
	},
	__createObject: function(swf1) {
		(openfl_display_MovieClip().default).__constructor = $bind(this,this.__constructor);
		this.swf = swf1;
		var symbolType = null;
		if(this.className != null) {
			symbolType = (Type().default).resolveClass(this.className);
			var tmp = symbolType == null;
		}
		if(symbolType == null && this.baseClassName != null) {
			symbolType = (Type().default).resolveClass(this.baseClassName);
			var tmp1 = symbolType == null;
		}
		var movieClip = null;
		if(symbolType != null) {
			movieClip = (Type().default).createInstance(symbolType,[]);
		} else {
			movieClip = new (openfl_display_MovieClip().default)();
		}
		return movieClip;
	},
	__init: function(swf1) {
		(openfl_display_MovieClip().default).__constructor = $bind(this,this.__constructor);
		this.swf = swf1;
	},
	__initObject: function(swf1,instance) {
		this.swf = swf1;
		this.__constructor(instance);
	}
});
SpriteSymbol.prototype.__class__ = SpriteSymbol.prototype.constructor = $hxClasses["swf.exporters.swflite.SpriteSymbol"] = SpriteSymbol;

// Init



// Statics




// Export

exports.default = SpriteSymbol;