// Class: openfl.display._internal.CanvasSimpleButton

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;

// Constructor

var CanvasSimpleButton = function(){}

// Meta

CanvasSimpleButton.__name__ = "openfl.display._internal.CanvasSimpleButton";
CanvasSimpleButton.__isInterface__ = false;
CanvasSimpleButton.prototype = {
	
};
CanvasSimpleButton.prototype.__class__ = CanvasSimpleButton.prototype.constructor = $hxClasses["openfl.display._internal.CanvasSimpleButton"] = CanvasSimpleButton;

// Init



// Statics

CanvasSimpleButton.renderDrawable = function(simpleButton,renderer) {
	if(!simpleButton.__renderable || simpleButton.__worldAlpha <= 0 || simpleButton.__currentState == null) {
		return;
	}
	renderer.__pushMaskObject(simpleButton);
	renderer.__renderDrawable(simpleButton.__currentState);
	renderer.__popMaskObject(simpleButton);
	renderer.__renderEvent(simpleButton);
}
CanvasSimpleButton.renderDrawableMask = function(simpleButton,renderer) {
	renderer.__renderDrawableMask(simpleButton.__currentState);
}


// Export

exports.default = CanvasSimpleButton;