// Class: swf.exporters.swflite.ButtonSymbol

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;
var $import = require("./../../../import_stub").default;
var $bind = require("./../../../bind_stub").default;
var $extend = require("./../../../extend_stub").default;
function swf_exporters_swflite_SWFSymbol() {return require("./../../../swf/exporters/swflite/SWFSymbol");}
function openfl_display_SimpleButton() {return require("./../../../openfl/display/SimpleButton");}
function Type() {return require("./../../../Type");}

// Constructor

var ButtonSymbol = function() {
	(swf_exporters_swflite_SWFSymbol().default).call(this);
}

// Meta

ButtonSymbol.__name__ = "swf.exporters.swflite.ButtonSymbol";
ButtonSymbol.__isInterface__ = false;
ButtonSymbol.__super__ = (swf_exporters_swflite_SWFSymbol().default);
ButtonSymbol.prototype = $extend((swf_exporters_swflite_SWFSymbol().default).prototype, {
	__constructor: function(simpleButton) {
		if(this.downState != null) {
			simpleButton.set_downState(this.downState.__createObject(this.swf));
		}
		if(this.hitState != null) {
			simpleButton.set_hitTestState(this.hitState.__createObject(this.swf));
		}
		if(this.overState != null) {
			simpleButton.set_overState(this.overState.__createObject(this.swf));
		}
		if(this.upState != null) {
			simpleButton.set_upState(this.upState.__createObject(this.swf));
		}
	},
	__createObject: function(swf1) {
		var simpleButton = null;
		(openfl_display_SimpleButton().default).__constructor = $bind(this,this.__constructor);
		this.swf = swf1;
		if(this.className != null) {
			var symbolType = (Type().default).resolveClass(this.className);
			if(symbolType != null) {
				simpleButton = (Type().default).createInstance(symbolType,[]);
			}
		}
		if(simpleButton == null) {
			simpleButton = new (openfl_display_SimpleButton().default)();
		}
		return simpleButton;
	},
	__init: function(swf1) {
		(openfl_display_SimpleButton().default).__constructor = $bind(this,this.__constructor);
		this.swf = swf1;
	},
	__initObject: function(swf1,instance) {
		this.swf = swf1;
		this.__constructor(instance);
	}
});
ButtonSymbol.prototype.__class__ = ButtonSymbol.prototype.constructor = $hxClasses["swf.exporters.swflite.ButtonSymbol"] = ButtonSymbol;

// Init



// Statics




// Export

exports.default = ButtonSymbol;