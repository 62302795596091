Object.defineProperty (module.exports, "__esModule", { value: true });
module.exports.Context3DStencilAction = module.exports.default = {
	DECREMENT_SATURATE: "decrementSaturate",
	DECREMENT_WRAP: "decrementWrap",
	INCREMENT_SATURATE: "incrementSaturate",
	INCREMENT_WRAP: "incrementWrap",
	INVERT: "invert",
	KEEP: "keep",
	SET: "set",
	ZERO: "zero"
};