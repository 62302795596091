// Class: openfl.utils._AGALMiniAssembler.OpCode

var $global = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this

$global.Object.defineProperty(exports, "__esModule", {value: true});

var __map_reserved = {};

// Imports

var $hxClasses = require("./../../../hxClasses_stub").default;
var $hxEnums = require("./../../../hxEnums_stub").default;

// Constructor

var OpCode = function(name,numRegister,emitCode,flags) {
	this.name = name;
	this.numRegister = numRegister;
	this.emitCode = emitCode;
	this.flags = flags;
}

// Meta

OpCode.__name__ = "openfl.utils._AGALMiniAssembler.OpCode";
OpCode.__isInterface__ = false;
OpCode.prototype = {
	toString: function() {
		return "[OpCode name=\"" + this.name + "\", numRegister=" + this.numRegister + ", emitCode=" + this.emitCode + ", flags=" + this.flags + "]";
	}
};
OpCode.prototype.__class__ = OpCode.prototype.constructor = $hxClasses["openfl.utils._AGALMiniAssembler.OpCode"] = OpCode;

// Init



// Statics


OpCode.__meta__ = { obj : { SuppressWarnings : ["checkstyle:FieldDocComment"]}}

// Export

exports.default = OpCode;