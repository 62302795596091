module.exports = {
	// Application: require("./Application").default,
	Bitmap: require("./Bitmap").default,
	BitmapData: require("./BitmapData").default,
	BitmapDataChannel: require("./BitmapDataChannel").default,
	BlendMode: require("./BlendMode").default,
	CanvasRenderer: require("./CanvasRenderer").default,
	CapsStyle: require("./CapsStyle").default,
	DisplayObject: require("./DisplayObject").default,
	DisplayObjectContainer: require("./DisplayObjectContainer").default,
	DisplayObjectRenderer: require("./DisplayObjectRenderer").default,
	DisplayObjectShader: require("./DisplayObjectShader").default,
	DOMElement: require("./DOMElement").default,
	DOMRenderer: require("./DOMRenderer").default,
	FPS: require("./FPS").default,
	FrameLabel: require("./FrameLabel").default,
	GradientType: require("./GradientType").default,
	Graphics: require("./Graphics").default,
	GraphicsBitmapFill: require("./GraphicsBitmapFill").default,
	GraphicsEndFill: require("./GraphicsEndFill").default,
	GraphicsGradientFill: require("./GraphicsGradientFill").default,
	GraphicsPath: require("./GraphicsPath").default,
	GraphicsPathCommand: require("./GraphicsPathCommand").default,
	GraphicsPathWinding: require("./GraphicsPathWinding").default,
	GraphicsQuadPath: require("./GraphicsQuadPath").default,
	GraphicsShader: require("./GraphicsShader").default,
	GraphicsShaderFill: require("./GraphicsShaderFill").default,
	GraphicsSolidFill: require("./GraphicsSolidFill").default,
	GraphicsStroke: require("./GraphicsStroke").default,
	GraphicsTrianglePath: require("./GraphicsTrianglePath").default,
	IBitmapDrawable: require("./IBitmapDrawable").default,
	IGraphicsData: require("./IGraphicsData").default,
	IGraphicsFill: require("./IGraphicsFill").default,
	IGraphicsPath: require("./IGraphicsPath").default,
	IGraphicsStroke: require("./IGraphicsStroke").default,
	InteractiveObject: require("./InteractiveObject").default,
	InterpolationMethod: require("./InterpolationMethod").default,
	ITileContainer: require("./ITileContainer").default,
	JointStyle: require("./JointStyle").default,
	JPEGEncoderOptions: require("./JPEGEncoderOptions").default,
	LineScaleMode: require("./LineScaleMode").default,
	Loader: require("./Loader").default,
	LoaderInfo: require("./LoaderInfo").default,
	MovieClip: require("./MovieClip").default,
	OpenGLRenderer: require("./OpenGLRenderer").default,
	PixelSnapping: require("./PixelSnapping").default,
	PNGEncoderOptions: require("./PNGEncoderOptions").default,
	Preloader: require("./Preloader").default,
	Shader: require("./Shader").default,
	ShaderData: require("./ShaderData").default,
	ShaderInput: require("./ShaderInput").default,
	ShaderJob: require("./ShaderJob").default,
	ShaderParameter: require("./ShaderParameter").default,
	ShaderParameterType: require("./ShaderParameterType").default,
	ShaderPrecision: require("./ShaderPrecision").default,
	Shape: require("./Shape").default,
	SimpleButton: require("./SimpleButton").default,
	SpreadMethod: require("./SpreadMethod").default,
	Sprite: require("./Sprite").default,
	Stage: require("./Stage").default,
	Stage3D: require("./Stage3D").default,
	StageAlign: require("./StageAlign").default,
	StageDisplayState: require("./StageDisplayState").default,
	StageQuality: require("./StageQuality").default,
	StageScaleMode: require("./StageScaleMode").default,
	Tile: require("./Tile").default,
	TileContainer: require("./TileContainer").default,
	Tilemap: require("./Tilemap").default,
	Tileset: require("./Tileset").default,
	TriangleCulling: require("./TriangleCulling").default,
	// Window: require("./Window").default
}